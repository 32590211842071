import { EditTablePage } from "./lib/edit_table_page.js";

class AccountsCheapVpsPage {
  setup_edit_table_page() {
    this.edit_table_page = new EditTablePage('table', '/accounts/cheap_vps', '',
                                    () => this.on_table_loaded(),
                                    (row) => this.on_new_row()
                                  );
  }

  on_table_loaded() {
  }

  on_new_row() {
  }

  load_cheap_vps(pattern, page) {
    this.edit_table_page.load_table(pattern, page);
  }

  setup_reset_filter_callback(){
    this.edit_table_page.setup_reset_filter_callback();
  }

  setup_pagination() {
    this.edit_table_page.setup_pagination();
  }
}

$(document).on('turbo:load', function() {
  if ($("body.accounts_cheap_vps.index").length == 0) {
    return;
  }
  console.log("page load in accounts_cheap_vps.js");

  var page = new AccountsCheapVpsPage();
  page.setup_edit_table_page();
  page.load_cheap_vps();
  page.setup_pagination();
  page.setup_reset_filter_callback();
} );
