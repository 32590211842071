function TrialsSysbenchPage(sysbench_data_content_id) {
  this.sysbench_data_content_id = sysbench_data_content_id;

  this.load_modal = function(sysbench_run_link_id) {
    var sbr_link_id_element = $(sysbench_run_link_id);
    if (sbr_link_id_element.length == 0) {
      console.error(sysbench_run_link_id + " is missing from trials sysbench page");
      return;
    }
    console.log("sbr_link_id_element=" + sbr_link_id_element.data('sysbench-run-id'));

    var sysbench_run_id = sbr_link_id_element.data('sysbench-run-id');

    $(this.sysbench_data_content_id).load("/sysbench_runs/" +  sysbench_run_id + ".html");
  }
}



$(document).on('turbo:load', function() {
  if ($(".trials.sysbench").length == 0) {
    return;
  }
  console.log("page load in trials_sysbench.js");

  var trial_sysbench_page = new TrialsSysbenchPage("#sysbench_data_content");
  var sbr_element_id;

  $('.sysbench-run-link').click(function(e) {
    sbr_element_id = e.target.id;
  });

  $('#sysbench_data').on('show.bs.modal', function (e) {
    trial_sysbench_page.load_modal("#" + sbr_element_id);
  });
});
