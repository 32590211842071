import { SelectizeMultipleSelect } from "./lib/selectize_multiple_select";

export default class PlansSelect {
  constructor(endpoint) {
    this.endpoint = endpoint;
    this.initPlansSelectBox();
    this.setupCallbacks();
  }

  setupCompareClicks(compare_keyword, compare_endpoint) {
    if (compare_endpoint === this.endpoint) {
      return;
    }

    $('#comp-' + compare_keyword + '-go').on('click', (e) => {
      var selected = $('#plans-select-box').val();
      var dest_url = '/compare/performances/' + compare_endpoint + '?selected_plan_ids=' +
        encodeURIComponent(selected);
      Turbo.visit(dest_url);
    });
  }

  setupCallbacks() {
    $('#submit-plans').on('click', (e) => {
      e.preventDefault();
      var selected = $('#plans-select-box').val();

      var url = `/compare/performances/${this.endpoint}?selected_plan_ids=` + encodeURIComponent(selected);
      Turbo.visit(url);
    });

    this.setupCompareClicks('sysbench', 'sysbench');
    this.setupCompareClicks('web', 'web');
    this.setupCompareClicks('web-runs', 'web_runs');
    this.setupCompareClicks('endurance', 'endurance');
    this.setupCompareClicks('remote', 'remote_timing');
    this.setupCompareClicks('transfers', 'network_transfers');
  }

  disableAllButtons() {
    $(".comp-submit").each( (index, element) => { $(element).prop('disabled', true); } );
  }

  enableAllButtons() {
    $(".comp-submit").each( (index, element) => { $(element).prop('disabled', false); } );
  }

  setButtonsStatus() {
    if ($('#plans-select-box').val().length == 0) {
      this.disableAllButtons();
    } else {
      this.enableAllButtons();
    }
  }

  initPlansSelectBox() {
    console.log("initPlansSelectBox");
    this.setButtonsStatus();

    var selectizeBox = new SelectizeMultipleSelect("#plans-select-box", 'Type provider or plan', 'plans');
    selectizeBox.init_select();
    selectizeBox.setup_select_changes();

    $("#plans-select-box").on('change', (event, params) => {
      this.setButtonsStatus();
    });
  }
}
