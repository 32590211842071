class Orders {
  init() {
    this.plans = [ '#basic-provider-panel', '#featured-provider-panel', '#featured-plus-panel' ];

    for (var plan of this.plans) {
      if ($(`${plan} input:radio`).prop('checked') === true) {
        $(`${plan}`).addClass("box-shadow-colored");

        if (plan == '#basic-provider-panel') {
          $(".only-with-trials").addClass('d-none');
        }
      }
    }
  }

  option_panel_click_handler() {
    var plans = this.plans;
    for (var plan of plans) {
      $(plan).on('click', (e) => {
        var panel = e.currentTarget;
        var panel_id = $(panel).prop('id');
        console.debug(`click on ${panel_id}`);
        $(panel).addClass("box-shadow-colored");
        $(`#${panel_id} input:radio`).prop('checked', true);

        var new_price = $(`#${panel_id}`).data('price');
        console.log(`new_price = ${new_price}`);
        $('#total').text(new_price);

        for (var oplan of plans) {
          if (oplan != `#${panel_id}`) {
            $(oplan).removeClass("box-shadow-colored");
          }
        }

        if (panel_id == 'basic-provider-panel' ) {
          $(".only-with-trials").addClass('d-none');
        }
        else {
          $(".only-with-trials").removeClass('d-none');
        }
      });
    }
  }
}

$(document).on('turbo:render', function() {
  // Handle 422 response on form submit error (turbo:load does not fire on 4xx)
  if ($("body.orders.create").length == 0) {
    return;
  }
  console.log("page render in orders.js");

  var orders = new Orders();
  orders.init();
  orders.option_panel_click_handler();
});

$(document).on('turbo:load', function() {
  if ($("body.orders.new").length == 0 && $("body.orders.create").length == 0) {
    return;
  }
  console.log("page load in orders.js");


  var orders = new Orders();
  orders.init();
  orders.option_panel_click_handler();
});
