import VChartsLoader from './lib/vcharts_loader.js';
import PlansSelect from './plans_select';

class CompareEndurancePage {
  constructor() {
    this.vchartsLoader = new VChartsLoader();
  }

  init() {
    this.initPlansSelect();
    this.vchartsLoader.init();
    this.load_all_charts();
  }

  initPlansSelect() {
    this.plansSelect = new PlansSelect('endurance');
  }

  load_chart(canvas) {
    if (canvas.length == 0) {
      console.error(canvas_id + " is missing from compare endurance page");
      return;
    }

    var metric_type = canvas.data('metric-type');
    var selected_plan_ids = canvas.data('selected-plan-ids');
    var selected_trial_ids = canvas.data('selected-trial-ids');

    var ctx = canvas.get(0).getContext("2d");

    var self = this;
    $.ajax({
      type: 'GET',
      url: '/compare/performances/endurance/chart_data?' +
          'metric_type=' + metric_type +
          '&trial_ids=' + encodeURIComponent(selected_trial_ids),
      data: { },
      dataType: 'json',
      success: function (data) {
        console.log("success endurance charts_data");
        self.display_chart(ctx, data);
      }
    })
  }

  display_chart(ctx, data) {
    this.vchartsLoader.vpsb_bar_chart_improved(ctx, data);
  }

  load_all_charts() {
    var self = this;
    $('.chart-canvas').each(function(canvas, value) {
      self.load_chart($(this));
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.compare_endurance.index").length == 0 && $("body.compare_endurance.show").length == 0) {
    return;
  }
  console.log("page load in compare_endurance.js");

  var compare_endurance_page = new CompareEndurancePage();
  compare_endurance_page.init();
} );
