class ReferralManager {
  amend_referral_forms(parent) {
    var selector;
    if (typeof parent !== undefined) {
      selector = $('form.referral-redirect-form', parent)
    } else {
      selector = $('form.referral-redirect-form');
    }
    selector.each( (index, form) => {
      if ($('input[name="path"].referral-dynamic', form).length == 0) {
        console.debug(`amend_referral_forms: adding path input`);
        var path = window.location.pathname;
        $(form).append(`<input type="hidden" name="path" value="${path}" class="referral-dynamic" />`);
      }
    });
  }

  referral_click_callback(e) {
    // Send the data using post
    var referral_url = $(e.currentTarget).data('href');
    var plan_id = $(e.currentTarget).data('plan-id');
    var url_override = $(e.currentTarget).data('url-override');
    var origin = $(e.currentTarget).data('origin');

    var query_params = {};
    if (url_override != "") {
      query_params['url_override'] = url_override;
    }
    if (plan_id != "") {
      query_params['plan_id'] = plan_id;
    }
    if (origin != "") {
      query_params['origin'] = origin;
    }

    console.log("referral_click_callback " + referral_url);
    var refer = $.ajax({
      url: referral_url,
      ajax: true,
      method: 'GET',
      data: query_params
    });

    // Put the results in a div
    refer.done( (data) => {
      $("#referral-modal-wrapper").empty().append(data);
      $("#referral-modal").modal({ show: true });

      this.amend_referral_forms("#referral-modal");
      this.init_modal_other_hoster_links();
    });

    refer.fail( (data) => {
      console.error("Failed to fetch referral data");
    });

    refer.always( (data) => {} );
  };

  setup_referral_buttons_callbacks() {
    $(".referral-modal-link").on('click', (e) => {
      e.preventDefault();
      this.referral_click_callback(e);
    });

    this.amend_referral_forms();
  };

  setup_plain_referral_links() {
    $('body.vpsb').on('click', 'a.plain-referral-link', (e) => {
      var hoster_id = $(e.currentTarget).data('hoster-id');
      var origin = $(e.currentTarget).data('origin') || 'plain_referral_link';
      var target_url = $(e.currentTarget).attr('href');
      this.log_referral_click(hoster_id, window.location.pathname, origin, target_url);
    });
  }

  setup_in_table_referral_links(links_selector) {
    $('body.vpsb').on('click', links_selector, (e) => {
      var hoster_id = $(e.currentTarget).parents('tr').data('hoster-id');
      hoster_id ||= $(e.currentTarget).data('hoster-id');

      var origin = $(e.currentTarget).parents('tr').data('origin');
      origin ||= $(e.currentTarget).data('origin');

      var target_url = $(e.currentTarget).attr('href');
      this.log_referral_click(hoster_id, window.location.pathname, origin, target_url);
    });
  }

  setup_referral_open_new_tab() {
    $('body.vpsb').on('click', 'a.referral-open-new-tab', (e) => {
      var t = $(e.currentTarget);
      var target_url = t.attr('href');

      if (t.data('no_prevent_default') !== true) {
        e.preventDefault();
        console.debug(`click a.referral-open-new-tab logging referral`);
        this.log_referral_click(t.data('hoster-id'), window.location.pathname, t.data('origin'), target_url);
        t.data('no_prevent_default', true);
        t[0].click();
      } else {
        console.debug(`click a.referral-open-new-tab navigating to target in new tab`);
      }
    });
  }

  log_referral_click(hoster_id, path, origin, target_url, success_callback) {
    var meta = $('meta[name=csrf-token]');
    var csrfToken = meta.attr('content');

    $.ajax({
      url : "/api/hosters/log_referral",
      type: "POST",
      headers: { "X-CSRF-Token": csrfToken },
      data : { hoster_id: hoster_id,
               path: path,
               origin: origin,
               target_url: target_url
              }
    }).done( (data, textStatus, jqXHR) => {
      console.log("log_referral_click success");
      if (success_callback) {
        success_callback();
      }
    }).fail( (jqXHR, textStatus, errorThrown) => {
      console.error("log_referral_click error", textStatus, errorThrown);
    })
  }

  init_modal_other_hoster_links() {
    var self = this;
    $(".modal-link-close").each(function () {
      $(this).on('click', function(e) {
        $('#referral-modal').modal('toggle');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        $('#referral-modal').remove();
      });
    })
  }
}

export function log_referral_click(hoster_id, path, origin, target_url, success_callback) {
  var referral_manager = new ReferralManager();
  referral_manager.log_referral_click(hoster_id, path, origin, target_url, success_callback);
}

export function setup_referral_click_callbacks() {
  var referral_manager = new ReferralManager();
  referral_manager.setup_referral_buttons_callbacks();
}


$(document).on('turbo:load', function() {
  var referral_manager = new ReferralManager();
  referral_manager.setup_referral_buttons_callbacks();
  referral_manager.setup_referral_open_new_tab();
  referral_manager.setup_plain_referral_links();
  referral_manager.setup_in_table_referral_links('a.product-range-provider-link,a.bandwidth-price-link,a.storage-price-provider-link');
})
