class StoragePricesPage {
  setup_menu_link_callback() {
    $('.menu-link').each(function() {
      $(this).on('click', function(event) {
        var target = $(event.target).attr('href');
        $('html, body').animate({
          scrollTop: $(target).offset().top - $('.vpsb-navbar').height()
        }, 500);
        window.location.hash = target.substr(1);
        return false;
      });
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.hosters.all_storage_prices").length == 0) {
    return;
  }
  console.log("page load in storage_prices.js...");

  var storage_prices_page = new StoragePricesPage();
  storage_prices_page.setup_menu_link_callback();
} );

