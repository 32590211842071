import { showEphemeral, show_layout_modal } from '../global';
import { EditTable } from "./edit_table.js";
import { EditTablePagination } from "./edit_table_pagination.js";

export class EditTablePage {
  constructor(load_table_endpoint, path_prefix, path_suffix = '', on_table_loaded, on_new_row) {
    this.load_table_endpoint = load_table_endpoint;
    this.path_prefix = path_prefix;
    this.path_suffix = path_suffix;
    this.on_table_loaded = on_table_loaded;
    this.on_new_row = on_new_row;
  }

  load_table(pattern, page) {
    console.log(`${this.path_prefix}/${this.load_table_endpoint}`);
    $.ajax({
      method: 'GET',
      url: `${this.path_prefix}/${this.load_table_endpoint}`,
      data: { pattern: pattern, page: page },
      dataType: 'html',
      success: (data) => {
        console.debug("Success - ajax callback done");
        $('#edit-table-wrapper').html(data);
        this.pagination.update_buttons_state();
        this.setup_edit_table();
      },
      error: (data) => {
        console.error(data)
        showEphemeral("Error. Could not load data.");
      }
    });
  }

  setup_edit_table() {
    var edit_table = new EditTable(true, this.path_prefix, this.path_suffix);
    edit_table.init();
    if (typeof this.on_table_loaded !== 'undefined') {
      this.on_table_loaded();
    }
    edit_table.setOnNewRowCallback(this.on_new_row);
    this.setup_hoster_filter();
  }

  setup_hoster_filter() {
    $('#hoster-filter').on("input", () => {
      if (this.hosterFilterTimerId) {
        clearTimeout(this.hosterFilterTimerId);
      }

      var pattern = $('#hoster-filter').val().toLowerCase();
      this.hosterFilterTimerId = setTimeout(() => {
        this.load_table(pattern);
        this.hosterFilterTimerId = undefined;
      }, 400);
    });
  }

  setup_reset_filter_callback(){
    $('#reset-btn').on('click', (e) => {
      e.preventDefault();
      $('#hoster-filter').val('');
      this.load_table();
    });
  }

  setup_pagination() {
    this.pagination = new EditTablePagination()
    this.pagination.setup_pagination_callbacks((pattern, page) => this.load_table(pattern, page));
  }
}