import CookieManager from './cookie_manager';
import { vpsbGetPagesInSession } from './global';
import { show_layout_modal } from "./global";
import 'jquery-ui/ui/effects/effect-shake';
import 'jquery-ui/ui/effects/effect-fold';
import 'jquery-ui/ui/effects/effect-fade';

import 'jquery-ui/ui/effect';

class EmailSubscription {
  constructor() {
    this.cookies = new CookieManager();
    this.subscribePopup = '#subscribe-sidebar-popup';
  }

  unhidePopup() {
    var hideEmailSubsPopup = (this.cookies.get('hideEmailSubsPopup') == "true") || localStorage.getItem('email-subscribed');
    var numPages = vpsbGetPagesInSession(this.cookies);
    if ($(window).width() >= 760) {
      if (numPages > 2 && !hideEmailSubsPopup) {
          window.setTimeout( () => {
          $(this.subscribePopup).removeClass('d-none');
          $(this.subscribePopup).show("fold").delay(1000).effect("shake");
        }, 2000);
      }
    }
  }

  setupFormSubmit() {
    $('form.email-subscription-form[data-remote="true"]').on('submit', (event) => {
      var formElt = event.currentTarget;
      event.preventDefault();

      $.ajax({
        method: 'POST',
        url: $(formElt).attr('action'),
        data: $(formElt).serialize(),
        success: (data) => {
          console.log('POST subscription success');
          $('#subscription-wait-confirm-content').html(data);
          $('#subscription-wait-confirm').modal('show');
          this.cookies.set('hideEmailSubsPopup', true, 30);
          localStorage.setItem('email-subscribed', true);
          $(this.subscribePopup).addClass('d-none');
          $(".subs-email-field").val('');
        },
        error: (data) => {
          var respBody = data.responseJSON;
          show_layout_modal(`Something went wrong: ${respBody.error}`);
          console.log(`err: ${respBody.error}, code: ${data.status}`);
        }
      });
    });
  }

  setupCloseCallback() {
    if ($('#close-subscribe-panel').length == 0 || $(this.subscribePopup).length == 0) {
      return;
    }

    $('#close-subscribe-panel').on('click', (event) => {
      $(this.subscribePopup).hide("fade");
      this.cookies.set('hideEmailSubsPopup', true, 10);

      var meta = $('meta[name=csrf-token]');
      var csrfToken;
      if (meta.length == 0) {
        csrfToken = "";
      } else {
        csrfToken = meta.attr('content');
      }

      $.ajax({
        type: "POST",
        headers: { "X-CSRF-Token": csrfToken },
        url: "/subscriptions/close_popup"
      })
    });
  }
}



$(document).on('turbo:load', function() {
  if ($(".email-subscription-form").length == 0) {
    return;
  }

  var email_subscription = new EmailSubscription();
  email_subscription.unhidePopup();
  email_subscription.setupFormSubmit();
  email_subscription.setupCloseCallback();
});
