import VChartsLoader from './lib/vcharts_loader.js';

class CompareTestGroupsPage {
  constructor() {
    this.vchartsLoader = new VChartsLoader();
  }

  init() {
    this.vchartsLoader.init();
    this.load_all_charts();
  }

  load_all_charts() {
    $('.chart-canvas').each((index, element) => {
      this.load_chart($(element));
    });
  }

  load_chart(canvas) {
    if (canvas.length == 0) {
      console.error(canvas_id + " is missing from compare test groups page");
      return;
    }

    var metric_name = canvas.data('metric-name');
    var test_group_ids = canvas.data('test-group-ids');

    var ctx = canvas.get(0).getContext("2d");

    var self = this;
    $.ajax({
      type: 'GET',
      url: `/compare/performances/test_groups/${test_group_ids}/chart_data/${metric_name}`,
      data: { },
      dataType: 'json',
      success: function (data) {
        console.log("success test groups charts_data");
        self.display_chart(ctx, data);
      }
    })
  }

  display_chart(ctx, data) {
    this.vchartsLoader.vpsb_bar_chart_improved(ctx, data);
  }
}

$(document).on('turbo:load', function() {
  if ($("body.compare_test_groups.show").length == 0 ) {
    return;
  }
  console.log("page load in compare_test_groups.js");

  var compare_test_groups_page = new CompareTestGroupsPage();
  compare_test_groups_page.init();
} );
