import VChartsLoader from './lib/vcharts_loader.js';

class LabsCorrelation {
  constructor() {
    this.vchartsLoader = new VChartsLoader();
  }

  init() {
    this.vchartsLoader.init();
    var metrics = [ 'wr_max_rate_wo_errors', 'sysbench_cpu_num_ops' ];
    var group_by_color = 'price'
    this.request_chart_data(metrics[0], metrics[1], group_by_color);
    this.set_select_option_by_value('xaxis', metrics[0]);
    this.set_select_option_by_value('yaxis', metrics[1]);
    this.set_select_option_by_value('color', group_by_color);
    this.set_select_callbacks();
  }

  request_chart_data(xaxis_value, yaxis_value, group_by_color) {
    $.ajax({
      type: 'GET',
      url: '/labs/correlation_chart_data',
      data: {
        xaxis: xaxis_value,
        yaxis: yaxis_value,
        group_by_color: group_by_color
      },
      dataType: 'json',
      success: (data) => {
        console.log("success correlation charts_data");
        this.display_chart(data);
      }
    })
  }

  set_select_option_by_text(select_id, text) {
    $("#" + select_id + " > option").each(function(){
      if($(this).text() == text) {
        $(this).parent('select').val($(this).val());
      }
    })
  }

  set_select_option_by_value(select_id, value) {
    $("#" + select_id).val(value);
  }

  set_select_callbacks() {
    $('select').on('change', (e) => {
        // console.log("this id = " + $(this).attr('id'));
        // console.log("valueSelected = " + this.value);
        // console.log("text = " + $(this).find('option:selected').text());

        this.request_chart_data($('#xaxis option:selected').val(),
                                $('#yaxis option:selected').val(),
                                $('#color option:selected').val());
    });
  }

  display_chart(data) {
    // Destroy existing charts or they will flicker
    if (typeof this.correl_chart !== 'undefined') {
      console.log("destroying correl_chart");
      this.correl_chart.destroy();
    }

    var chartId = "correlChart";
    var ctx = $(`#${chartId}`);
    this.correl_chart = this.vchartsLoader.vpsb_scatter_chart(ctx, data, chartId);
  }
}

$(document).on('turbo:load', function() {
  if ($("body.labs.correlation").length == 0) {
    return;
  }
  console.log("page load in labs_correlation.js");

  var labs_correlation = new LabsCorrelation();
  labs_correlation.init();
});
