import { vpsb_lazy_load_images } from '../vpsb/lazy_loader.js';

class TrialsIndexPage {
  init() {
    console.debug("table container length", $("#table-container").children().length);
    if ($("#table-container").children().length == 0) {
      this.load_table();
    }
  }

  load_table() {
    var url = "/trials/index_table";
    var params = {};

    $.get(url, params, (data) => {
      $("#table-container").html(data);
      vpsb_lazy_load_images();
    }).fail(function() {
      console.error("trials table async call failed");
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.trials.index").length == 0 && $("body.trials.index_hoster").length == 0) {
    return;
  }
  console.log("page load in trials_index.js...");

  var trial_index_page = new TrialsIndexPage();
  trial_index_page.init();
} );
