import CookieManager from './cookie_manager';
import 'jquery-ui/ui/effect';

class AnnouncementBar {
  constructor() {
    this.cookies = new CookieManager();
    this.annBar = '#announcement-bar';
  }

  hideBar() {
    var hideAnnouncementBar = this.cookies.get('hideAnnouncementBar');
      if (hideAnnouncementBar === "true") {
        $(this.annBar).addClass('d-none');
      }
  }


  setupCloseCallback() {
    $('.announcement-banner #close-announcement-bar').on('click', (event) => {
      event.preventDefault();
      $(this.annBar).slideUp();
      this.cookies.set('hideAnnouncementBar', true, 10);
    });
  }
}


$(document).on('turbo:load', function() {
  if ($(".announcement-banner #close-announcement-bar").length == 0) {
    return;
  }

  var annBar = new AnnouncementBar();
  annBar.hideBar();
  annBar.setupCloseCallback();
});
