export class StripeElements {
  constructor(stripe_attrs) {
    this.stripe_js_url = $(stripe_attrs).data('stripe-js-url');
    this.stripe_pk = $(stripe_attrs).data('stripe-publishable-key');
    this.stripe_client_secret = $(stripe_attrs).data('stripe-client-secret');
    this.stripe_success_url = $(stripe_attrs).data('stripe-success-url');
  }

  initialize() {
    $.getScript(this.stripe_js_url)
      .done(( script, textStatus ) => {
        console.log("Loaded Stripe JS successfully");
        this.stripe = Stripe(this.stripe_pk);

        this.elements = this.stripe.elements({ theme: 'stripe', clientSecret: this.stripe_client_secret });

        const paymentElement = this.elements.create("payment");
        paymentElement.mount("#payment-element");

        this.setup_form_submit();
      })
      .fail(( jqxhr, settings, exception ) => {
        console.error("Failed to load Stripe SDK");
      });
  }

  setup_form_submit() {
    $('#payment-form').on('submit', (e) => {
      this.handleSubmit(e);
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.setLoading(true);

    var elements = this.elements;
    const { error } = await this.stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: this.stripe_success_url,
      },
    });

    this.checkStatus();

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        this.showMessage(error.message);
      } else {
        this.showMessage("An unexpected error occured.");
      }
    }

    this.setLoading(false);
  }

  async checkStatus() {
    console.log("StripeElements checkStatus");
    const { paymentIntent } = await this.stripe.retrievePaymentIntent(this.stripe_client_secret);

    switch (paymentIntent.status) {
      case "succeeded":
        this.showMessage("Payment succeeded!");
        break;
      case "processing":
        this.showMessage("Your payment is processing.");
        break;
      case "requires_payment_method":
        this.showMessage("Your payment was not successful, please try again.");
        break;
      default:
        this.showMessage("Something went wrong.");
        break;
    }
  }

  setLoading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      document.querySelector("#submit").disabled = true;
      document.querySelector("#spinner").classList.remove("hidden");
      document.querySelector("#button-text").classList.add("hidden");
    } else {
      document.querySelector("#submit").disabled = false;
      document.querySelector("#spinner").classList.add("hidden");
      document.querySelector("#button-text").classList.remove("hidden");
    }
  }

  showMessage(messageText) {
    const messageContainer = document.querySelector("#payment-message");
    messageContainer.classList.remove("hidden");
    messageContainer.textContent = messageText;

    setTimeout(function () {
      messageContainer.classList.add("hidden");
      messageContainer.textContent = "";
    }, 4000);
  }
}
