import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/slider.css';
import 'jquery-ui/ui/widgets/slider';
import { vpsb_lazy_load_images } from '../vpsb/lazy_loader.js';
import { SelectizeMultipleSelect } from './lib/selectize_multiple_select.js';

class ScreenerIndexPage {
  constructor() {
    this.submitDelay = 600;
  }

  submit_form_handler() {
    $( "#screener-form" ).on('submit', (event) => {
      event.preventDefault();
      this.submit_form_with_delay();
    });
  }

  submit_form_with_delay() {
    if (this.sliderTimerId) {
      clearTimeout(this.sliderTimerId);
      this.sliderTimerId = setTimeout(() => {
        this.submit_form();
        this.sliderTimerId = undefined;
      }, this.submitDelay);
    } else {
      this.submit_form();
      this.sliderTimerId = setTimeout(() => {
        this.sliderTimerId = undefined;
      }, this.submitDelay);
    }
  }

  submit_form() {
    // Get some values from elements on the page:
    var $form = $("#screener-form"),
      params = {
        price_max: $("#price-slider").slider("values", 1),
        price_min: $("#price-slider").slider("values", 0),
        num_cores_min: $("#num-cores-slider").slider("values", 0),
        num_cores_max: $("#num-cores-slider").slider("values", 1),
        memory_gb_min: $("#memory-gb-slider").slider("values", 0),
        memory_gb_max: $("#memory-gb-slider").slider("values", 1),
        disk_gb_min: $("#disk-gb-slider").slider("values", 0),
        disk_gb_max: $("#disk-gb-slider").slider("values", 1),
        hosters: $("#selectize-hosters-select").val(),
        burstable: $('#burstable').prop('checked'),
        dedicated: $('#dedicated').prop('checked'),
        price_weighted: $('#price_weighted').prop('checked'),
        include_private: $('#screener_include_private').val(),
        grades_version: $('#screener_grades_version').val(),
        indie_providers: $('#screener_indie_providers').val(),
        countries: $('#selectize-countries-select').val()
      },
      url = $form.attr( "action" );

    var fields = [ "#price", "#disk-gb", "#num-cores", "#memory-gb" ];
    for (var i = 0; i < fields.length; i++) {
      var field = fields[i];
      $(field + "-slider").attr('data-init-min', $(field + "-slider").slider("values", 0));
      $(field + "-slider").attr('data-init-max', $(field + "-slider").slider("values", 1));
    }

    // Send the data using post
    $.ajax( {
      method: 'POST',
      url: url,
      data: {
        screener: params,
        sort_by: $("input[name=sort]:checked").val()
      },
      success: (data) => {
        console.log("Screener submit_form success");
        $("#results").empty().append(data);
        $('[data-toggle="popover"]').popover();

        if ($(window).width() >= 992) {
          $([document.documentElement, document.body]).animate({
            scrollTop: $("#start-of-list").offset().top - $('.vpsb-navbar').height() - 10
          }, 700);
        }

        this.update_plan_counts();
        vpsb_lazy_load_images();

      },
      error: (data) => {
        alert("Failed to fetch screener data");

      },
      always: (data) => {
        $("#submit-screen-button").removeAttr('disabled');
      }
    });
  }

  stopCallbackClosure(field) {
    var self = this;
    return function(event, ui) {
      self.submit_form_with_delay();
    }
  }

  slideCallbackClosure(field) {
    var self = this;
    return function(event, ui) {
      $(field + "-label").html(ui.values[0] + " - " + ui.values[1] );
    }
  }

  update_plan_counts() {
    var featured_count = $("#plan-counts").data("featured");
    var non_featured_count = $("#plan-counts").data("non-featured");

    $("#span-featured-count").html(featured_count);
    $("#span-non-featured-count").html(non_featured_count);
  }

  init_sliders() {
    var fields = [ "#price", "#disk-gb", "#num-cores", "#memory-gb" ];
    for (var i = 0; i < fields.length; i++) {
      var field = fields[i];
      $( field + "-slider" ).slider({
        range: true,
        min: $(field + "-slider").data('min'),
        max: $(field + "-slider").data('max'),
        step: $(field + "-slider").data('step'),
        values: [ $(field + "-slider").data('init-min'), $(field + "-slider").data('init-max') ],
        stop: this.stopCallbackClosure(field),
        slide: this.slideCallbackClosure(field)
      });

      $(field + "-label").html($(field + "-slider").slider("values", 0) + " - " +
        $(field + "-slider").slider("values", 1));
    }
  }

  init_countries() {
    var selectizeCountries = new SelectizeMultipleSelect('#selectize-countries-select', 'Type country names', 'countries', 'All', () => { this.submit_form_with_delay(); });
    selectizeCountries.init_select();
    selectizeCountries.setup_select_changes();
  }

  init_hosters() {
    var selectizeHosters = new SelectizeMultipleSelect('#selectize-hosters-select', 'Type provider names', 'hosters', '0', () => { this.submit_form_with_delay(); });
    selectizeHosters.init_select();
    selectizeHosters.setup_select_changes();
  }

  init_sort_buttons() {
    var self = this;

    $("input[name=sort]:radio").bind("change", function(event, ui) {
      self.submit_form_with_delay();
    });
  }

  init_checkbox_element(element) {
    $('[data-toggle="popover"]').popover();

    element.change(() => {
      this.submit_form_with_delay();
    });
  }
}


$(document).on('turbo:load', function() {
  if ($("body.screener").length == 0) {
    return;
  }
  console.log("page load in screener.js");

  if ($(window).width() >= 992) {
    $(".sticky-position").css("top", $('.vpsb-navbar').height() + 30);
  }

  $(window).on('resize', () => {
    if ($(window).width() >= 992) {
      $(".sticky-position").css("top", $('.vpsb-navbar').height() + 30);
    } else {
      $(".sticky-position").css("top", '');
    }
  });

  var screener_page = new ScreenerIndexPage();
  screener_page.update_plan_counts();
  screener_page.submit_form_handler();
  screener_page.init_sliders();
  screener_page.init_countries();
  screener_page.init_sort_buttons();
  screener_page.init_hosters();
  screener_page.init_checkbox_element($('#burstable'));
  screener_page.init_checkbox_element($('#dedicated'));
  screener_page.init_checkbox_element($('#price_weighted'));
});
