import VChartsLoader from './lib/vcharts_loader.js';
import { intersectionObserverLazyLoad, show_layout_modal } from './global';

class EnduranceRunPage {
  constructor(chart_data_id, canvas_id) {
    this.chart_data_id = chart_data_id;
    this.canvas_id = canvas_id;
    this.vchartsLoader = new VChartsLoader();
  }

  init() {
    this.vchartsLoader.init();
    this.extract_data();
    this.setupLazyLoadCharts();
  }

  setupLazyLoadCharts() {
    intersectionObserverLazyLoad("lazy-chart", "300px", (container) => {
      this.display_chart();
    });
  }

  display_chart() {
    console.log("display_chart, canvas_id=" + this.canvas_id);

    var canvas = $(this.canvas_id);
    var ctx = canvas.get(0).getContext("2d");

    $.ajax({
      type: 'GET',
      url: "/trials/" + this.trial_id + "/endurance_runs/" + this.endurance_run_id + "/time_chart_data",
      dataType: 'json',
      success: (data) => {
        console.log("success endurance time_chart_data");
        this.vchartsLoader.vpsb_line_chart_dual_axis(ctx, data);
      }
    })
  }

  extract_data() {
    var data_element = $(this.chart_data_id);
    if (data_element.length == 0) {
      console.warn(this.data_elt_id + " is missing from trials remote timing page");
      return;
    }
    console.log("endurance_run_id=" + data_element.data('endurance-run-id' + " trial_id=" + data_element.data('trial-id')));

    this.trial_id = parseInt(data_element.data('trial-id'));
    this.endurance_run_id = parseInt(data_element.data('endurance-run-id'));
  }
}


$(document).on('turbo:load', function() {
  if ($("body.endurance_runs.show").length == 0) {
    return;
  }
  console.log("page load in endurance_runs.js");

  var endurance_runs_page = new EnduranceRunPage("#endurance_data", "#endurance_canvas");
  endurance_runs_page.init();
});
