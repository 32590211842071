import { show_layout_modal, intersectionObserverLazyLoad } from './global';
import VChartsLoader from './lib/vcharts_loader.js';
import { YabCards } from './lib/yabs_table';

export class CompareYabsPage {
  constructor(yabs_table) {
    this.vchartsLoader = new VChartsLoader();
    this.yabs_table = yabs_table;
  }

  init() {
    this.initial_load();
    this.setup_add_buttons();
    this.setup_remove_buttons();
    this.setup_compare_button();
    this.setupLazyLoadCharts();
  }

  initial_load() {
    console.debug("Selected yab ids: ", $('#yabs-selected-table-container').data('selected-ids'));
    var selected_ids = $('#yabs-selected-table-container').data('selected-ids') + '';
    selected_ids = selected_ids.split(',');
    selected_ids = selected_ids.filter( (value) => { return value !== "" });
    if (selected_ids.length === 0) {
      $('#compare-yabs-btn').prop('disabled', true);
      return;
    }

    console.debug('Loading selected table and results');
    this.load_selected_table(selected_ids);
    this.load_results(selected_ids);
  }

  setup_add_buttons() {
    $('#yabs-list-container').on('click', '.yab-row .add-button', (event) => {
      event.preventDefault();
      var yab_id = $(event.currentTarget).parents('tr.yab-row').data('yab-id') + '';
      var selected_ids = $('#yabs-selected-table-container').data('selected-ids').split(',');
      selected_ids.push(yab_id);
      selected_ids = selected_ids.filter(word => word.length > 0);
      selected_ids = [...new Set(selected_ids)];
      var num_selected = selected_ids.length;

      var max_yabs_comparison = $('#yabs-list-container #yabs-table').data('max-yabs-comparison');
      if (num_selected > max_yabs_comparison) {
        show_layout_modal(`No more than ${max_yabs_comparison} yabs in comparison please.`);
        return;
      }

      this.load_selected_table(selected_ids, $(event.currentTarget).parents('tr.yab-row'));
    });
  }

  load_selected_table(selected_ids, removable_row) {
    var ids_string = selected_ids.join(',');
    var num_selected = selected_ids.length;
    $.get(`/compare/performances/yabs/selected_table?yab_ids=${ids_string}`, (data) => {
      console.log("success add selected table");
      $(removable_row).remove();
      $('#yabs-selected-table-container').data('selected-ids', ids_string)
      $('#yabs-selected-table-container').html(data);
      $('#yabs-selected-table-container').prepend(`<p>You have selected ${num_selected} yabs for comparison.</p>`);
      if (num_selected > 0) {
        $('#compare-yabs-btn').prop('disabled', false);
      }

    }).fail((data) => {
      show_layout_modal("Failed to get add yab to select list");
    })
  }

  setup_remove_buttons() {
    $('#yabs-selected-table-container').on('click', '.yab-row .remove-button', (event) => {
      event.preventDefault();
      var yab_id = $(event.currentTarget).parents('tr.yab-row').data('yab-id') + '';
      var selected_ids = $('#yabs-selected-table-container').data('selected-ids').split(',');
      var index = selected_ids.indexOf(yab_id);
      var num_selected = selected_ids.length;
      if (index !== -1) {
        selected_ids.splice(index, 1);
        num_selected = selected_ids.length;
        selected_ids = selected_ids.join(',');
        $('#yabs-selected-table-container').data('selected-ids', selected_ids);
      }
      if (num_selected === 0) {
        $('#compare-yabs-btn').prop('disabled', true);
      }
      $(event.currentTarget).parents('tr.yab-row').remove();
      this.yabs_table.load_list($("#yabs-table").data('sort-column'), $("#yabs-table").data('sort-order'));
    })
  }

  setup_compare_button() {
    $('#compare-yabs-btn').on('click', (event) => {
      event.preventDefault();
      var selected_ids = $('#yabs-selected-table-container').data('selected-ids').split(',');
      this.load_results(selected_ids);

      $('html, body').animate({
        scrollTop: $('#comparison-container').offset().top -100
    }, 1000);
    });
  }

  load_results(selected_ids) {
    var ids_string = selected_ids.join(',');
    $.get('/compare/performances/yabs/display', { yab_ids: ids_string }, (data) => {
      $('#comparison-container').html(data);
      this.setupLazyLoadCharts();

      console.log("Loading YabCards");
      var yabCards = new YabCards(true);
      yabCards.init();
    }).fail( (data) => {
      console.log('compare yabs display failed');
    });
  }

  setupLazyLoadCharts() {
    intersectionObserverLazyLoad("lazy-chart", "300px", (container) => {
      this.load_chart(`#${container.id}`);
    });
  }

  load_chart(canvasId) {
    var canvas = $(canvasId);
    if (canvas.length == 0) {
      console.error(canvas_id + " is missing from yabs page");
      return;
    }

    this.vchartsLoader.init();

    var ctx = canvas.get(0).getContext("2d");
    var yab_ids = canvas.data('yab-ids');
    var test_name = canvas.data('test-name');
    var block_size = canvas.data('block-size');
    $.ajax({
      type: 'GET',
      url: `/compare/performances/yabs/chart_data/${test_name}`,
      data: { yab_ids: yab_ids, block_size: block_size },
      dataType: 'json',
      success: (data) => {
        console.log("success yabs charts_data");
        this.display_chart(ctx, data);
      }
    })
  }

  display_chart(ctx, data) {
    this.vchartsLoader.vpsb_bar_chart_improved(ctx, data);
  }
}
