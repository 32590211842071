import { show_layout_modal } from "../global";

export class EditTable {

  constructor(allowNew, path_prefix, path_suffix = '') {
    this.allowNew = allowNew;
    this.path_prefix = path_prefix;
    this.path_suffix = path_suffix;
  }

  init() {
    console.debug("EditTable init");
    this.setupEditButtons();
    this.setupCancelButtons();
    this.setupSaveButtons();
    this.setupDestroyButtons();
  }

  setupEditButtons() {
    $('.edit-button').each( (index, element) => {
      this.setupSingleEditButton($(element));
    });
  }

  setupCancelButtons() {
    $('table#edit-table').on('click', '.cancel-button', (event) => {
      event.preventDefault();
      var editItemRow = $(event.currentTarget).parents('tr.edit-item-row');
      var itemId = editItemRow.data('item-id');
      $(`tr#show-item-${itemId}`).removeClass('d-none');
      $(`tr#edit-item-${itemId}`).addClass('d-none');
    });
  }

  setupDestroyButtons() {
    $('table#edit-table').on('click', '.destroy-button', (event) => {
      event.preventDefault();
      var showItemRow = $(event.currentTarget).parents('tr.show-item-row');
      var itemId = showItemRow.data('item-id');
      $.ajax({
        method: "DELETE",
        url: `${this.path_prefix}/${itemId}${this.path_suffix}`,
        dataType: 'json',
        success: function() {
          console.log("delete success");
          $(`tr#show-item-${itemId}`).remove();
          $(`tr#edit-item-${itemId}`).remove();
            },
        error: (data) => {
	        var respBody = data.responseJSON;
          console.error(`err: ${respBody.error}, code: ${data.status}`);
          show_layout_modal(respBody.error);
        }
      });
    });
  }

  setupSaveButtons() {
    var meta = $('meta[name=csrf-token]');
    var csrfToken = meta.attr('content');
    $('table#edit-table').on('click', '.save-button', (event) => {
      event.preventDefault();
      var editItemRow = $(event.currentTarget).parents('tr.edit-item-row');
      var itemId = editItemRow.data('item-id');
      var params = {};
      $(`tr#edit-item-${itemId} .item-param`).each( (index, elt) => {
        var val;
        if ($(elt).attr('type') === 'checkbox') {
          val = $(elt).is(':checked');
        } else {
          val = $(elt).val();
        }
        params[$(elt).attr('name')] = val;
      });
      $.ajax({
        method: "PUT",
        url: `${this.path_prefix}/${itemId}${this.path_suffix}`,
        headers: { "X-CSRF-Token": csrfToken },
        contentType: "application/json",
        data: JSON.stringify(params),
        success: (data, textStatus, xhr) => {
          console.debug("update success");
          var showItemRow  = $(`tr#show-item-${itemId}`)
          var editItemRow = $(`tr#edit-item-${itemId}`);
          var newShowItemRow = $($.parseHTML(data));
          showItemRow.replaceWith(newShowItemRow);

          itemId = newShowItemRow.data("item-id");
          editItemRow.attr("id", `edit-item-${itemId}`);
          editItemRow.data('item-id', itemId);
          editItemRow.attr("data-item-id", itemId);

          this.setupSingleEditButton($(`#edit-btn-${itemId}`));
          newShowItemRow.removeClass('d-none');
          $(`tr#edit-item-${itemId}`).addClass('d-none');
          $(`tr#edit-item-${itemId}`).removeClass('new-item');

          if (this.allowNew === true && $('.new-item').length == 0) {
            this.prependNewRow();
          }
        },
        error: (data, textStatus, xhr) => {
	        var respBody = data.responseJSON;
          console.error(`err: ${respBody.error}, code: ${data.status}`);
          show_layout_modal(respBody.error);
        }
      });
    });
  }

  setupSingleEditButton(btnElt) {
    btnElt.on('click', (event) => {
      event.preventDefault();
      var itemId = $(event.currentTarget).data('item-id');
      $(`tr#show-item-${itemId}`).addClass('d-none');
      $(`tr#edit-item-${itemId}`).removeClass('d-none');
      $(`tr#edit-item-${itemId} #name`).trigger('focus');
      $(`tr#edit-item-${itemId} #name`).trigger('select');
    });
  }

  prependNewRow() {
    $.get(`${this.path_prefix}/new${this.path_suffix}`, (data) => {
      var newShowItemRow = $($.parseHTML(data));
      $("tr.show-item-row:first").before(newShowItemRow);
      this.setupSingleEditButton(newShowItemRow.find('a.edit-button'));

      if (typeof this.onNewRow !== 'undefined') {
        this.onNewRow(newShowItemRow);
      }
    }).fail( (data, textStatus, xhr) => {
      var respBody = data.responseJSON;
      console.error(`err: ${respBody.error}, code: ${data.status}`);
      show_layout_modal(respBody.error);
    });
  }

  setOnNewRowCallback(callback) {
    this.onNewRow = callback;
  }
}
