import { vpsb_insert_human_token, show_layout_modal } from './global';

export class LoginModal {
  constructor(triggering_form, options) {
    this.triggering_form = triggering_form;
    this.options = options;
  }

  load(label) {
    $.ajax({
      method: "GET",
      url: "/login_modal/show",
      success: (data) => {
        $('#login-modal-wrapper').html(data);
        $('#login-modal-label').html(label);

        if (this.options.show_skip_button) {
          $('#skip-button-container').removeClass('hidden');
          if (this.options.skip_button_label) {
            $('#skip-button').html(this.options.skip_button_label);
          }
          $('#skip-button').on('click', (e) => {
            e.preventDefault();
            this.submitTriggeringForm();
          });
        }

        this.setupLoginModalShown();
        this.show();
      },
      error: function(data) {
        console.log(`err: ${data}, code: ${data.status}`);
        alert(data);
      }
    });
  }

  show() {
    $('#login-modal').modal();
  }

  setupLoginModalShown() {
    $('#login-modal').on('shown.bs.modal', (event) => {

      $('#login-button').on('click', (event) => {
        event.preventDefault();
        $('#login-or-signup').addClass('hidden');
        $('#login-form-wrapper').removeClass('hidden');
        $('#login-form-wrapper #user_email').focus();
        this.setupLoginForm();
      });

      $('#signup-button').on('click', (event) => {
        event.preventDefault();
        $('#login-or-signup').addClass('hidden');
        $('#signup-form-wrapper').removeClass('hidden');
        $('#signup-form-wrapper #user_first_name').focus();
        vpsb_insert_human_token('#new_user_form');
        this.setupSignupForm();
      });
    });
  }

  setupLoginForm() {
    $('#new_session_form').on('submit', (event) => {
      $('#login-form-alert').addClass('hidden');
      var formElt = event.currentTarget;
      event.preventDefault();
      $.ajax({
        url: $(formElt).prop('action'),
        type: 'POST',
        dataType: 'json',
        data : $(formElt).serialize(),
        success: (data) => {
          $('#login-form-wrapper').addClass('hidden');
          this.showProceedButton("Logged in successfully");
        },
        error: (data, textStatus, xhr) => {
          var respBody = data.responseJSON;
          $('#login-form-alert').html(respBody.error);
          $('#login-form-alert').removeClass('hidden');
          console.log(`err: ${respBody.error}, code: ${data.status}`);
        }
      });
      return false;
    });
  }

  setupSignupForm() {
    $('#new_user_form').on('submit', (event) => {
      $('#signup-form-alert').addClass('hidden');
      var formElt = event.currentTarget;
      event.preventDefault();
      $.ajax({
        url: $(formElt).prop('action'),
        type: 'POST',
        dataType: 'json',
        data : $(formElt).serialize(),
        success: (data) => {
          $('#signup-form-wrapper').addClass('hidden');
          this.showProceedButton("Signed up successfully");
        },
        error: (data) => {
          var respBody = data.responseJSON;
          $('#signup-form-alert').html(JSON.stringify(respBody.errors));
          $('#signup-form-alert').removeClass('hidden');
          console.log(`err: ${JSON.stringify(respBody.errors)}, code: ${data.status}`);
        }
      });
      return false;
    });
  }

  showProceedButton(label) {
    console.debug('showProceedButton with label', label);
    $('#proceed-form-label').html(label);
    $('#login-result').removeClass('hidden');

    $('#proceed-button').off('click');
    $('#proceed-button').on('click', (e) => {
      e.preventDefault();
      console.debug('showProceedButton proceed button click');
      this.submitTriggeringForm();
    });
  }

  submitTriggeringForm() {
    var targetUrl = $(this.triggering_form).prop('action');
    var targetMethod = $(this.triggering_form).prop('method');
    var targetParams = $(this.triggering_form).serialize();
    $.ajax({
      method: targetMethod,
      url: targetUrl,
      data: targetParams,
      dataType: 'json',
      success: (data) => {
        console.log('redirecting to ', data.redirect);
        Turbo.visit(data.redirect);
      },
      error: (data) => {
        console.log(`err: ${data.responseJSON.errors.join(', ')}, code: ${data.status}`);
        $('#login-modal').modal('hide');
        show_layout_modal(data.responseJSON.errors.join(', '));
      }
    });
  }
}

