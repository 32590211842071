import { intersectionObserverLazyLoad } from './global';

class HosterPage {

  init_screenshots() {
    $('#screenshot-modal').on('show.bs.modal', function (event) {
      var button = $(event.relatedTarget) // Button that triggered the modal
      var screenshot_url = button.data('screenshot-url');
      var screenshot_name = button.data('screenshot-name');
      $('#modal-image').html("<img class='img-responsive' src='" + screenshot_url + "' alt='" + screenshot_name + "' >");
    })
  }

  setup_private_plans_checkbox() {
    var checkbox = $('#show_private_plans');

    checkbox.on('click', (event) => {
      this.set_plans_visibility();
    });
  }

  set_plans_visibility() {
    var checkbox = $('#show_private_plans');

    if (checkbox.prop('checked') || checkbox.length == 0) {
      $('tr.private-plan').removeClass('d-none');
      $('tr.private-range').removeClass('d-none');
    }
    else {
      $('tr.private-plan').addClass('d-none');
      $('tr.private-range').addClass('d-none');
    }
  }

  setup_popovers() {
    intersectionObserverLazyLoad("table-with-popovers", "80px", (container) => {
      $(`a[data-toggle="popover"]`, container).popover();
    });
  }
}

class HosterOverTime {
  constructor() {
    $(`a[data-toggle="popover"]`).popover();
  }

  setupPriceWeightedSwitch() {
    var hosterId = $('.price_weighted_switch').data('hoster-id');
    $(`input#price_weighted`).on('change', (e) => {
      $.ajax({
        url: `/hosters/${hosterId}/consistency_table`,
        data: { price_weighted: $(`input#price_weighted`).prop('checked')},
        success: (data) => {
          $(`#consistency_table`).html(data);
        },
        error: () => {
          console.log("consistency_table error");
        }
      });
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.hosters.consistency").length > 0) {
    var hot = new HosterOverTime();
    hot.setupPriceWeightedSwitch();
  }

  if ($("body.hosters.show,body.hosters.managed_vps_providers").length == 0) {
    return;
  }
  console.log("page load in hoster.js");

  var hoster_page = new HosterPage();
  hoster_page.init_screenshots();
  hoster_page.set_plans_visibility();
  hoster_page.setup_private_plans_checkbox();
  hoster_page.setup_popovers();
});
