import VChartsLoader from './lib/vcharts_loader.js';
import { intersectionObserverLazyLoad, show_layout_modal } from './global';

class TrialWebRunsPage {
  constructor(canvas_id) {
    this.canvas_id = canvas_id;
    this.vchartsLoader = new VChartsLoader();
  }

  init() {
    this.vchartsLoader.init();
    this.setupLazyLoadCharts();
  }

  setupLazyLoadCharts() {
    intersectionObserverLazyLoad("lazy-chart", "300px", (container) => {
      this.display_chart();
    });
  }

  display_chart() {
    var canvas = $(this.canvas_id);
    var ctx = canvas.get(0).getContext("2d");
    var trial_id = parseInt(canvas.data('trial-id'));
    var url = "/trials/" + trial_id + "/web_runs_latency_data";

    var test_group_id = canvas.attr('data-test-group-id');
    if (test_group_id.length > 0) {
      url += `/test_group/${test_group_id}`
    }

    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        console.log("success web_runs latency_chart_data");
        this.vchartsLoader.vpsb_line_chart_dual_axis(ctx, data);
      }
    })
  }
}


$(document).on('turbo:load', function() {
  if ($(".trials.web_runs").length == 0) {
    return;
  }
  console.log("page load in trial_web_runs.js");

  var trial_web_runs_page = new TrialWebRunsPage("#web-runs-canvas");
  trial_web_runs_page.init();
});
