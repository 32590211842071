import { show_layout_modal, setupAjaxCursor } from "./global.js";

class VpsTasks {

  init() {
    this.MAX_INTERVALS = 200;
  }

  load_tasks(keepAutoUpdating) {
    var vpsId = this.vps_id();
    $.get( `/vps_instances/${vpsId}/tasks`, (data) => {
      $("#tasks").html( data );
      this.refresh_state_in_table();
      this.setup_reset_task();

      if (typeof this.intervalId != 'undefined') {
        $('#live-poll').attr('disabled', true);
        $('#live-poll').attr('title', 'Auto refresh is running');
      }

      if (typeof this.intervalId != 'undefined' &&
            ( [ 'Failed', 'Setup complete', 'Benchmarking complete', 'Modular benchmarking complete', 'Complete', 'Cancelled' ].includes(this.vps_state()) ) ) {
        console.debug("Stopping tasks auto refresh (vps state)");
        this.stop_auto_refresh();
      }

      if (keepAutoUpdating !== true) {
        if (typeof this.intervalId != 'undefined' && this.vps_current_list_modular() && this.vps_current_task()==='') {
          console.debug("Stopping tasks auto refresh (modular list and no current task)");
          this.stop_auto_refresh();
        }
      }

      if (typeof this.intervalId === 'undefined') {
        $('.elapsed').addClass('hidden');
      }

      if (!this.cancellable_vps()) {
        $('#cancel').attr('disabled', true);
        $('#full-reset').attr('disabled', true);
      }

      if (this.vps_current_task() !== "") {
        $('.start-task-btn').attr('disabled', true);
      } else {
        $('.start-task-btn').attr('disabled', false);
      }

      if (this.vps_state() === "complete") {
        $('#cancel-reset-row').addClass('d-none');
      }
    }).fail( (data) => {
      console.error("Failed to load task list");
      console.log(data);
      this.stop_auto_refresh();
    });
  }

  update_state(what, list_name) {
    var vpsId = this.vps_id();
    var data = { what: what };
    if (list_name !== undefined) {
      data.list_name = list_name;
    }

    $.ajax({ method: "PUT",
      url: `/vps_instances/${vpsId}/update_state`,
      data: data,
      dataType: 'json',
      success: (data, textStatus, xhr) => {
        console.log("update_state success");
        this.load_tasks(true);

        if (['start_list', 'reset_task'].includes(what)) {
          this.start_auto_load_tasks();
        }
      },
      error: function(data, textStatus, xhr) {
        var respBody = data.responseJSON;
        console.error(`err: ${respBody.error}, code: ${data.status}`);
        show_layout_modal(respBody.error);
      }
    });
  }

  start_auto_load_tasks() {
    this.num_intervals = 0;
    this.start_auto_refresh();
  }

  start_auto_refresh() {
    if (this.intervalId!==undefined) {
      console.log("start_auto_refresh: interval already set");
      return;
    }

    console.log("start_auto_refresh: setting up interval");
    this.num_intervals = 0;
    this.intervalId = window.setInterval( () => {
      console.log(`Refreshing tasks table (num refreshes=${this.num_intervals})`);
      this.load_tasks();
      if (this.num_intervals++ > this.MAX_INTERVALS) {
        console.log(`Stopping tasks auto refresh after ${this.MAX_INTERVALS} intervals`);
        this.stop_auto_refresh();
      }
    }, 5000);
    $('#live-poll').attr('disabled', true);
    $('#live-poll').attr('title', 'Auto refresh is running');
  }

  stop_auto_refresh() {
    if (this.intervalId===undefined) {
      console.log("stop_auto_refresh: interval is not set");
      return;
    }

    clearInterval(this.intervalId);
    this.intervalId = undefined;
    this.num_intervals = 0;
    $('#live-poll').attr('disabled', false);
    $('#live-poll').attr('title', 'Continuously refresh page as long as tasks are running');
    $('.elapsed').addClass('hidden');
  }

  setup_live_poll() {
    if (this.intervalId !== undefined) {
      $('#live-poll').attr('disabled', true);
      return;
    } else {
      $('#live-poll').attr('disabled', false);
    }

    $('#tasks').on('click', '#live-poll', () => {
      this.load_tasks();
      this.start_auto_refresh();
    })
  }

  setup_refresh() {
    $('#tasks').on('click', '#refresh', () => {
      this.load_tasks();
    })
  }

  setup_start_list() {
    $('#tasks').on('click', '.start-list', (event) => {
      this.update_state('start_list', $(event.currentTarget).data('list-name'));
    })
  }

  setup_reset_task() {
    $('#reset-task').on('click', () => {
      this.update_state('reset_task');
    })
  }

  setup_full_reset() {
    $('#cancel-reset-row').on('click', '#full-reset', () => {
      this.update_state('full_reset');
      $('#cancel').attr('disabled', false);
    })
  }

  flash_success(msg) {
    var elt = "#flash-js";
    $(elt).html(msg);
    $(elt).removeClass("hidden");
    setTimeout( () => {
      $(elt).addClass("hidden");
    }, 10000);
  }

  setup_cancel() {
    var vpsId = this.vps_id();
    $('#cancel-reset-row').on('click', '#cancel', () => {
      var answer = confirm("Are you sure? This cannot be undone.");
      if (!answer) { return; }
      $.ajax({ method: "PUT",
        url: `/vps_instances/${vpsId}/cancel`,
        dataType: 'json',
        success: (data, textStatus, xhr) => {
          console.log("cancel success");
          this.set_state_in_table("Cancelled");
          $('#cancel').attr('disabled', true);
          this.flash_success("The setup was successfully cancelled, it will stop running when the current task completes.");
          setTimeout( () => {
            this.load_tasks();
          }, 2000);
        },
        error: function(data, textStatus, xhr) {
          var respBody = data.responseJSON;
          console.error(`err: ${respBody.error}, code: ${data.status}`);
          show_layout_modal(respBody.error);
        }
      });
    })
  }

  load_modal_and_fetch_logs(list, task_name, remote) {
    console.log(`load_modal_and_fetch_logs for task ${task_name}`);
    var vps_id = this.vps_id();

    $.get(`/vps_instances/${vps_id}/log/${list}/${task_name}?remote=${remote}`, (data) => {
      $('#vps-log-content').html(data);
      $('#vps-log-modal').modal();
    });
  }

  setup_vps_log_modals() {
    $('#tasks').on('click', '.vps-log-button', (e) => {
      var task_name = $(e.currentTarget).data('task-name');
      var list = $(e.currentTarget).data('list');
      var remote = $(e.currentTarget).data('remote-log');
      this.load_modal_and_fetch_logs(list, task_name, remote);
    });
  }

  setup_vps_kill_buttons() {
    $('#tasks').on('click', '.vps-kill-button', (e) => {
      var answer = confirm("Are you sure?");
      if (!answer) { return; }
      var taskName = $(e.currentTarget).data('task-name');
      var vpsId = this.vps_id();
      $.ajax({ method: "PUT",
        url: `/vps_instances/${vpsId}/kill_task/${taskName}`,
        dataType: 'json',
        success: (data, textStatus, xhr) => {
          console.log("kill success");
          this.flash_success("The current task was killed successfully. Retry when ready.");
          this.start_auto_refresh();
        },
        error: function(data, textStatus, xhr) {
          var respBody = data.responseJSON;
          console.error(`err: ${respBody.error}, code: ${data.status}`);
          show_layout_modal(respBody.error);
        }
      });
    });
  }

  refresh_state_in_table() {
    var state = this.vps_state();
    this.set_state_in_table(state);
  }

  set_state_in_table(state) {
    $('#vps-state').html(state);
  }

  vps_id() {
    return $('#vps-id').data('vps-id');
  }

  vps_state() {
    return $('#vps-state-src').data('vps-state');
  }

  vps_current_list() {
    return $('#vps-state-src').data('current-list');
  }

  vps_current_task() {
    var res = $('#vps-state-src').data('current-task');
    return res;
  }

  vps_next_lists() {
    return $('#vps-state-src').data('next-list').split(',');
  }

  vps_current_task_forked() {
    return $('#vps-state-src').data('current-task-forked') === true;
  }

  vps_current_list_modular() {
    return $('#vps-state-src').data('current-list-modular') === true;
  }

  cancellable_vps() {
    if ([ "New", "Setup", "Setup complete" ].includes(this.vps_state())) {
      return true;
    } else if (this.vps_state() == "Failed" && this.vps_current_list() == "setup") {
      return true;
    } else {
      return false;
    }
  }

  reset_test_group_modal() {
    $('#missing-title').addClass('d-none');
    $('#test-group-modal #title').val("");
    $('#test-group-modal #description').val("");
  }

  setup_modular_tasks() {
    $('#tasks').on('click', 'button#test-group-ok', (event) => {
      var title = $('#test-group-modal #title').val();
      var description = $('#test-group-modal #description').val();
      if (title === '') {
        $('#missing-title').removeClass('d-none');
        return;
      }
      $('#test-group-modal').modal('hide');

      this.start_modular_task(this.selectedTaskName, title, description);
    });

    $('#tasks').on('shown.bs.modal', '#test-group-modal', function () {
      $('#test-group-modal #title').trigger('focus');
    });

    $('#tasks').on('click', '.start-task-btn', (event) => {
      this.selectedTaskName = $(event.currentTarget).data('task-name');
      if (this.selectedTaskName === 'finish_benchmarking') {
        var answer = confirm("Are you sure? It won't be possible to run more tests on this VPS after you confirm.");
        if (answer) {
          this.start_modular_task('finish_benchmarking');
        }
        return;
      }

      this.reset_test_group_modal();
      $('#test-group-modal .modal-title').html(`Start ${this.selectedTaskName.replace('_', ' ')} Test`);
      $('#test-group-modal').modal();
    });
  }

  start_modular_task(taskName, title, description) {
    $.ajax({ method: "POST",
      url: `/vps_instances/${this.vps_id()}/start_task/${taskName}`,
      data: { title: title, description: description },
      dataType: 'json',
      success: (data, textStatus, xhr) => {
        console.log("start_modular_task success");
        $('.start-task-btn').attr('disabled', true);
        this.load_tasks();
        this.start_auto_load_tasks();
      },
      error: function(data, textStatus, xhr) {
        var respBody = data.responseJSON;
        console.error(`err: ${respBody.error}, code: ${data.status}`);
        show_layout_modal(respBody.error);
      }
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.vps_instances.show").length == 0) {
    return;
  }
  console.log("page load in vps_tasks.js");


  var vps_tasks = new VpsTasks();
  vps_tasks.init();
  vps_tasks.setup_vps_log_modals();
  vps_tasks.load_tasks();
  vps_tasks.setup_refresh();
  vps_tasks.setup_start_list();
  vps_tasks.setup_full_reset();
  vps_tasks.setup_cancel();
  vps_tasks.setup_live_poll();
  vps_tasks.setup_modular_tasks();
  vps_tasks.setup_vps_kill_buttons();

  setupAjaxCursor();
});

