$(document).on('turbo:load', function() {

  // workaround for https://github.com/turbolinks/turbolinks/issues/181
  // if ('scrollRestoration' in window.history) {
  //   window.history.scrollRestoration = 'manual';
  // }

  function navbarHeight() {
    return Math.round($('.vpsb-navbar').height());
  }

  function breadcrumbHeight() {
    if ($('#vpsb-breadcrumb').length === 0) {
      return 0;
    }

    if ($('#vpsb-breadcrumb').css("position") !== "fixed") {
      return 0;
    }

    return Math.round($('#vpsb-breadcrumb').height());
  }


  function adjustTopPaddingIfNecessary(eventName) {
    var offsetHeight = navbarHeight() + breadcrumbHeight() + 10;
    var currentPadding = parseInt($("html body").css("padding-top"));
    // console.debug(`${currentPadding} - ${offsetHeight}`);
    if (Math.abs(currentPadding - offsetHeight) > 3) {
      console.debug(`${eventName}: adjusting body padding to ${offsetHeight}`);
      $("html body").css("padding-top", offsetHeight);
      $('#vpsb-breadcrumb').css("top", navbarHeight());

      if ($(".accounts.vertical-nav").length > 0) {
        $(".accounts.vertical-nav").css("padding-top", offsetHeight);
      }
    }
  }

  adjustTopPaddingIfNecessary('On load');

  var resizeObserver = new ResizeObserver( entries => {
    for (const entry of entries) {
      adjustTopPaddingIfNecessary('Resize .vpsb-navbar');
    }
  });

  if ($(".vpsb-navbar").length > 0) {
    resizeObserver.observe($('.vpsb-navbar')[0]);
  }
});
