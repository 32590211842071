import { show_layout_modal } from '../global';

export class PayPalCheckout {
  setup_paypal_buttons(checkoutPage) {
    paypal.Buttons({
      createOrder: (data, actions) => {
        // This function sets up the details of the transaction, including the amount and line item details.
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: $('#order-attrs').data('order-total'),
              currency_code: "USD"
            }
          }],
          application_context: {
            brand_name: 'VPSBenchmarks',
            shipping_preference: 'NO_SHIPPING'
          }
        });
      },
      onApprove: (data, actions) => {
        console.log(data);
        // This function captures the funds from the transaction.
        return actions.order.capture().then( (details) => {
          this.payment_complete_handler(details);
        });
      },
      onCancel: (data) => {
        checkoutPage.cancel_order();
      }
    }).render('#paypal-button-container');
  }

  payment_complete_handler(details) {
    var meta = $('meta[name=csrf-token]');
    var csrfToken = meta.attr('content');
    var orderSuccessUrl = $('#order-attrs').data('order-success-url');
    $.ajax({
      method: 'POST',
      url: $('#order-attrs').data('payment-complete-url'),
      contentType: "application/json; charset=utf-8",
      headers: {
        'X-CSRF-Token': csrfToken
      },
      data: JSON.stringify({
        paypal_order_id: details.id,
        paypal_email: details.payer.email_address
      }),
      success: function(data, textStatus, xhr) {
        // console.log(data);
        $('#paypal-button-container').addClass('hidden');
        if (data.state === 'ok') {
          console.log("payment success");
          show_layout_modal("Thank you, your payment was successful.", orderSuccessUrl);
        } else {
          show_layout_modal(data.errors[0]);
          $('#paypal-button-container').removeClass('hidden');
        }
      }
    });
  }
}