$(document).on('turbo:load', function() {
  if ($(".accounts.vertical-nav").length == 0) {
    return;
  }
  console.log("loaded accounts_vert_nav.js");

  var path = `${window.location.pathname}${window.location.hash}`
  $('.accounts.vertical-nav .nav-item a').each((index, elt) => {
    var href = $(elt).attr('href');
    if (path === href) {
      $(elt).addClass("active");
    } else {
      $(elt).removeClass("active");
    }
  });
});
