import { PriceWeightedSwitch } from './lib/price_weighted_switch.js';

class TrialsShowPage {
  init() {
    $('[data-toggle="popover"]').popover();
    this.setupModularCompareButtons();
  }

  setupModularCompareButtons() {
    $('.compare-btn').on('click', (event) => {
      event.preventDefault();
      var element = event.currentTarget;
      var testType = $(element).data('test-type');
      console.log(`testType=${testType}`);
      var ids = [];
      $(`table.${testType} input[type='checkbox']:checked`).each( (index, element) => {
        ids.push($(element).data('test-group-id'));
      });
      console.log(ids);
      var url = `/compare/performances/test_groups/${testType}/${ids.join(',')}`;
      Turbo.visit(url);
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.trials.show").length == 0) {
    return;
  }
  console.log("page load in trials.js...");

  var priceWeightedSwitch = new PriceWeightedSwitch();
  priceWeightedSwitch.priceWeightedSwitchHandler();

  var trial_page = new TrialsShowPage();
  trial_page.init();
} );
