import VChartsLoader from './lib/vcharts_loader.js';

import { vpsb_deselect_all_checkboxes, intersectionObserverLazyLoad } from './global';
import { vpsb_lazy_load_images } from '../vpsb/lazy_loader.js';


class CompareAllPage {
  constructor() {
    this.hoster_ids = [];
    this.checked_boxes = [];
    this.vchartsLoader = new VChartsLoader();
  }

  init() {
    this.deselectAllButtonHandler();
    this.populateCheckboxes();
    this.check_num_checked_boxes();

    if ($("#data-holder").length > 0) {
      this.extract_data("#data-holder");
    }
    else {
      console.log("Missing data (no #data-holder element)");
      return;
    }

    this.setupLazyLoadCharts();
    this.setup_popovers();
    this.priceWeightedSwitchHandler();
  }

  deselectAllButtonHandler() {
    $("#uncheck-all").on('click', () => { this.checked_boxes = []; });
  }

  populateCheckboxes() {
    $('#features_provider_form input:checkbox:checked').each( (index, element) => {
      this.checked_boxes.push($(element).attr('id'));
    });
  }

  extract_data(data_elt_id) {
    var data_element = $(data_elt_id);
    if (data_element.length == 0) {
      console.error(data_elt_id + " is missing from home page");
      return;
    }
    console.debug("hoster_ids=" + data_element.data('hoster-ids'));

    var hoster_ids_strs = data_element.data('hoster-ids').toString().split(',');

    this.hoster_ids = hoster_ids_strs.map(function(s){return Number(s);});
    this.hosters_vs = data_element.data('hosters-vs');

    return this.hoster_ids;
  }

  priceWeightedSwitchHandler() {
    $('#price_weighted').change( (e) => {
      $.ajax({
        url: `/compare/${this.hosters_vs}/grades_table`,
        data: { price_weighted: $('#price_weighted').prop('checked')},
        success: (data) => {
          $('#grades-table-container').html(data);
          vpsb_lazy_load_images();
          // var score = $(`#grade-table-${planId}`).data('overall-score');
          // $(`#plan-score-${planId}`).html(score);
        },
        error: () => {
          console.log("grades_table error");
        }
      });
    });
  }

  setupLazyLoadCharts() {
    intersectionObserverLazyLoad("lazy-chart", "500px", (container) => {
      this.loadChartByCanvasId(`#${container.id}`);
    });
  }

  load_all_charts() {
    var chartsList = [ "#compareAllChart", '#compareAllWebRunRateChart',
                        "#compareAllEnduranceChart", "#compareAllEnduranceVariationChart",
                        "#compareAllSysbenchCpuChart", "#compareAllSysbenchIoChart", "#compareAllTransfersChart" ];
    for (var canvasId of chartsList) {
      if ($(canvasId).length > 0) {
        this.loadChartByCanvasId(canvasId);
      }
    }
  }

  loadChartByCanvasId(canvasId) {
    console.log(`loading chart for canvas ${canvasId}`);
    switch(canvasId) {
      case "#compareAllChart":
        this.load_web_chart(canvasId);
        break;
      case '#compareAllWebRunRateChart':
        this.load_web_run_rate_chart(canvasId);
        break;
      case "#compareAllEnduranceChart":
        this.load_endurance_chart(canvasId);
        break;
      case "#compareAllEnduranceVariationChart":
        this.load_endurance_variation_chart(canvasId);
        break;
      case "#compareAllSysbenchCpuChart":
        this.load_sysbench_cpu_chart(canvasId);
        break;
      case "#compareAllSysbenchIoChart":
        this.load_sysbench_diskio_chart(canvasId);
        break;
      case "#compareAllTransfersChart":
        this.load_transfers_chart(canvasId);
        break;
      default:
        console.error(`unknown canvas id ${canvasId}`);
    }
  }

  load_chart(canvas_id, path, label, query_params) {
    var canvas = $(canvas_id);
    if (canvas.length == 0) {
      console.error(`Canvas ${canvas_id} is not found`);
      return;
    }
    var ctx = canvas.get(0).getContext("2d");
    var canvas_id_alone = canvas_id.replace('#', '');

    Object.assign(query_params, { 'hoster_ids[0]': this.hoster_ids[0], 'hoster_ids[1]': this.hoster_ids[1] });

    $.ajax({
      type: 'GET',
      url: path,
      data: query_params,
      dataType: 'json',
      success: (data) => {
        this.vchartsLoader.vpsb_bar_chart(ctx, canvas_id_alone, data.labels, data.metrics, data.unit, label, 'vertical', false)
      }
    })
  }

  load_sysbench_cpu_chart(canvas_id) {
    var query_params = { metric_key: 'sysbench_cpu_num_ops' };
    this.load_chart(canvas_id, '/compare/chart_data', 'Number of operations per second', query_params);
  }

  load_sysbench_diskio_chart(canvas_id) {
    var query_params = { metric_key: 'sysbench_io_random_rd_rate_latest' };
    this.load_chart(canvas_id, '/compare/chart_data', 'MiB per second', query_params);
  }

  load_web_chart(canvas_id) {
    var query_params = { metric_key: 'web_latency' };
    this.load_chart(canvas_id, '/compare/chart_data', 'Average response time', query_params);
  }

  load_web_run_rate_chart(canvas_id) {
    var query_params = { metric_key: 'wr_max_rate_wo_errors' };
    this.load_chart(canvas_id, '/compare/chart_data', 'Request per second', query_params);
  }

  load_endurance_chart(canvas_id) {
    var query_params = { metric_key: 'endurance_num_ops' };
    this.load_chart(canvas_id, '/compare/chart_data', 'Number of operations per hour', query_params);
  }

  load_endurance_variation_chart(canvas_id) {
    var query_params = { metric_key: 'endurance_coeff_variation' };
    this.load_chart(canvas_id, '/compare/chart_data', 'Coefficient of variation', query_params);
  }

  load_transfers_chart(canvas_id) {
    var query_params = { metric_key: 'download_speed' };
    this.load_chart(canvas_id, '/compare/chart_data', 'Mbps', query_params);
  }

  check_num_checked_boxes() {
    $(".features_provider_box").change( (e) => {
      console.log(`checkbox change`);
      if(e.currentTarget.checked) {
        this.checked_boxes.push($(e.currentTarget).attr('id'));
        if (this.checked_boxes.length > 2) {
          var firstbox = this.checked_boxes.shift();
          $(`#${firstbox}`).prop('checked', false);
          $('#num-checked-warning').removeClass('hidden');
          setTimeout(function() {
              $("#num-checked-warning").addClass('hidden');
          }, 5000);
        }
      }
      else {
        var index = this.checked_boxes.indexOf($(e.currentTarget).attr('id'));
        if (index > -1) {
          this.checked_boxes.splice(index, 1);
        }
      }
    });
  }

  setup_popovers() {
    intersectionObserverLazyLoad("table-with-popovers", "80px", (container) => {
      $(`a[data-toggle="popover"]`, container).popover();
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.compare_all.show").length == 0 && $("body.compare_all.index").length == 0) {
    return;
  }
  console.log("page load in compare_all.js");


  var compare_all_page = new CompareAllPage();
  compare_all_page.init();

  vpsb_deselect_all_checkboxes("#uncheck-all", "#features_provider_form");
}
);
