import VChartsLoader from './lib/vcharts_loader.js';
import PlansSelect from './plans_select';

class CompareRemotePage {
  constructor() {
    this.vchartsLoader = new VChartsLoader();
  }

  init() {
    this.vchartsLoader.init();
    this.initPlansSelect();
    this.load_all_charts();
  }

  initPlansSelect() {
    this.plansSelect = new PlansSelect('remote_timing');
  }

  load_all_charts() {
    $('.chart-canvas').each( (index, element) => {
      this.load_chart($(element));
    });
  }

  load_chart(canvas) {
    if (canvas.length == 0) {
      console.error(canvas_id + " is missing from compare remote page");
      return;
    }

    var city = canvas.data('city');
    var selected_plan_ids = canvas.data('selected-plan-ids');
    var selected_trial_ids = canvas.data('selected-trial-ids');

    var ctx = canvas.get(0).getContext("2d");

    var self = this;
    $.ajax({
      type: 'GET',
      url: '/compare/performances/remote_timing/chart_data?' +
          'city=' + city +
          '&trial_ids=' + encodeURIComponent(selected_trial_ids),
      data: { },
      dataType: 'json',
      success: function (data) {
        console.log("success remote_timing charts_data");
        self.display_chart(ctx, data);
      }
    })
  }

  display_chart(ctx, data) {
    this.vchartsLoader.vpsb_bar_chart_improved(ctx, data);
  }
}

$(document).on('turbo:load', function() {
  if ($("body.compare_remote.index").length == 0 && $("body.compare_remote.show").length == 0) {
    return;
  }
  console.log("page load in compare_remote.js");

  var compare_remote_page = new CompareRemotePage();
  compare_remote_page.init();

} );
