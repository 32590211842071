import { vpsb_lazy_load_images } from '../vpsb/lazy_loader.js';
import { showEphemeral, show_layout_modal } from './global';
import VChartsLoader from './lib/vcharts_loader.js';
import { SelectizeMultipleSelect } from "./lib/selectize_multiple_select";

class PdfReportsPage {
  constructor() {
    this.vchartsLoader = new VChartsLoader();
  }

  init() {
    this.vchartsLoader.init();

    this.selectPlansLists = ["#pdf_report_publisher_plan_ids", "#pdf_report_other_plan_ids"];
    this.selectMetricList = "#pdf_report_metric_name";
    this.currentChart = {};

    this.setupSelectBoxes();
    this.setupPriceWeightedSwitch();
    this.setupDeleteMetric();
    this.setupMetricCommentEdit();
    this.setupMetricCommentSave();

    if ($('#plans-container').length > 0) {
      this.loadPlans();
    }

    if ($('.metric-chart-list').length > 0) {
      this.loadAllMetricCharts();
    }
  }

  setupSelectBoxes() {
    this.selectPlansLists.forEach((eltId) => {
      if ($(eltId).length == 0) {
        return;
      }

      this.setupCustomSelectList(eltId, 'Type provider or plan names', 'plans');

      $(eltId).on('change', (event, params) => {
        $('#pdf-report-config').data('use-pdf-report-plans', false);
        this.refreshPlansList(event);
      });
    });

    if ($(this.selectMetricList).length > 0) {
      this.setupCustomSelectList(this.selectMetricList, 'Type metric names', 'metrics');

      $(this.selectMetricList).on('change', (event, params) => {
        this.loadFirstMetricChartBasedOnSelected();
      });

      this.loadFirstMetricChartBasedOnSelected();
    }
  }

  loadPlans() {
    if (this.selectedPlanIds().length > 0 || $('#plans-container').data('num-plans') > 0) {
      this.refreshPlansList();
    }
  }

  setupCustomSelectList(eltId, placeholder, dataname) {
    console.log("eltId", eltId);
    var selectizeBox = new SelectizeMultipleSelect(eltId, placeholder, dataname);
    selectizeBox.init_select();
    selectizeBox.setup_select_changes();
  }

  setupPriceWeightedSwitch() {
    if ($('#grades-table-container').length == 0 ) {
      return;
    }

    if ($('#plan-grades-table').length > 0) {
      $('#pw-switch-row').removeClass('hidden');
      if ($('#plan-grades-table').data('price-weighted') === true) {
        $('#price_weighted').prop('checked', true);
        $('#pdf_report_price_weighted').prop('checked', true);
      }
      else {
        $('#price_weighted').prop('checked', false);
        $('#pdf_report_price_weighted').prop('checked', false);
      }
    }
    else {
      $('#pw-switch-row').addClass('hidden');
    }

    $('#price_weighted').unbind('change');
    $('#price_weighted').change((e) => {
      $('#pdf-report-config').data('use-pdf-report-plans', false);
      this.refreshPlansList();
    });
  }

  setupDeleteMetric() {
    var pdfReportId = $('#pdf-report-summary').data('pdf-report-id');
    var meta = $('meta[name=csrf-token]');
    var csrfToken = meta.attr('content');

    $(".delete-metric").on('click', (event) => {
      event.preventDefault();
      var metricName = $(event.currentTarget).data('metric-name');
      $.ajax({
        method: "PUT",
        headers: { "X-CSRF-Token": csrfToken },
        url: `/cloud_reports/${pdfReportId}/remove_metric/${metricName}`,
        success: (data) => {
          $('#pdf-report-summary').html(data);
          this.setupDeleteMetric();
          $(`.metric-section#${metricName}`).addClass('hidden');
        },
        error: () => {
          console.log("remove metric error");
        }
      });
    });
  }

  setupMetricCommentEdit() {
    $(".metric-comment-btn-edit").on('click', (event) => {
      event.preventDefault();
      var metricName = $(event.currentTarget).data('metric-name');
      $(`#${metricName}-comment-edit`).removeClass('hidden');
      $(`#${metricName}-comment-display`).addClass('hidden');
      $(`#${metricName}-comment-text`).focus();
    });
  }

  setupMetricCommentSave() {
    var pdfReportId = $('#pdf-report-summary').data('pdf-report-id');
    var meta = $('meta[name=csrf-token]');
    var csrfToken = meta.attr('content');

    $(".metric-comment-btn-save").on('click', (event) => {
      event.preventDefault();
      var metricName = $(event.currentTarget).data('metric-name');
      var comment = $(`#${metricName}-comment-text`).val();
      $.ajax({
        method: "PUT",
        headers: { "X-CSRF-Token": csrfToken },
        url: `/cloud_reports/${pdfReportId}/metric_comment/${metricName}`,
        data: { comment: comment },
        success: (data) => {
          showEphemeral("Your metric comment was saved successfully");

          var btnName = $(`#${metricName}-comment-btn`).html();
          if (comment.length > 0) {
            btnName = btnName.replace('Add', 'Edit');
          } else {
            btnName = btnName.replace('Edit', 'Add');
          }
          $(`#${metricName}-comment-btn`).html(btnName);

          $(`#${metricName}-comment-edit`).addClass('hidden');
          $(`#${metricName}-comment-btn-edit`).removeClass('hidden');
          $(`#${metricName}-comment-display-span`).html(comment);
          $(`#${metricName}-comment-display`).removeClass('hidden');
        },
        error: (data, textStatus, xhr) => {
          var respBody = data.responseJSON;
          console.log(respBody);
          show_layout_modal("Failed to save the comment: " + respBody);
        }
      });
    });

    $(".metric-comment-btn-cancel").on('click', (event) => {
      event.preventDefault();
      var metricName = $(event.currentTarget).data('metric-name');
      $(`#${metricName}-comment-edit`).addClass('hidden');
      $(`#${metricName}-comment-btn-edit`).removeClass('hidden');
      $(`#${metricName}-comment-display`).removeClass('hidden');
    });
  }

  selectedPlanIds() {
    var plan_ids = [];
    this.selectPlansLists.forEach((eltId) => {
      var ids = $(eltId).val();
      if (ids !== undefined) {
        plan_ids = plan_ids.concat($(eltId).val());
      }
    });
    return plan_ids;
  }

  pdfReportId() {
    var pdfReportId;
    if ($('#pdf-report-config').data('uuid-auth')) {
      pdfReportId = $('#pdf-report-config').data('pdf-report-uuid');
    } else {
      pdfReportId = $('#pdf-report-config').data('pdf-report-id');
    }
    return pdfReportId;
  }

  refreshPlansList() {
    var plan_ids = this.selectedPlanIds();

    var showPwSlider = $('#pdf-report-config').data('show-pw-slider');
    var usePdfReportPlans = $('#pdf-report-config').data('use-pdf-report-plans');

    $.ajax({
      method: "GET",
      url: `/cloud_reports/plans`,
      data: { plan_ids: plan_ids,
              price_weighted: $('#price_weighted').prop('checked'),
              pdf_report_id: this.pdfReportId(),
              show_pw_slider: showPwSlider,
              use_pdf_report_plans: usePdfReportPlans },
      success: (data) => {
        console.log("plans success");
        $('#plans-container').html(data);
        this.setupPriceWeightedSwitch();
        vpsb_lazy_load_images();
      },
      error: () => {
        console.error(`fetch plans error`);
      }
    });
  }

  refreshMetricChart(element, metricName, pdfReportId, idSuffix) {
    var canvas = $(element);
    var ctx = canvas.get(0).getContext("2d");
    var canvas_id = `metric-canvas${idSuffix}`;

    $.ajax({
      type: 'GET',
      url: `/cloud_reports/${pdfReportId}/chart_data/${metricName}`,
      data: { },
      dataType: 'json',
      success: (data) =>  {
        console.log(`success charts_data ${metricName}`);
        var lowerHigher;
        if (data.metric_config != null) {
          if (data.metric_config.higher_better) {
            lowerHigher = 'Higher is better';
          } else {
            lowerHigher = "Lower is better";
          }
          $(`#lower-higher${idSuffix}`).html(lowerHigher);
          $(`#desc${idSuffix}`).html(data.metric_config.desc + '.');
        }
        this.display_chart(canvas_id, ctx, data.chart_data);
      },
      error: () => {
        console.error("chart_data error");
      }
    })
  }

  loadFirstMetricChartBasedOnSelected() {
    var metricName = $(this.selectMetricList).val();
    var elt = '#metric-canvas';
    var pdfReportId = this.pdfReportId();

    this.refreshMetricChart(elt, metricName, pdfReportId, '');
  }

  loadAllMetricCharts() {
    $('.chart-canvas').each( (index, elt) => {
      var canvas = $(elt);
      var metricName = canvas.data('metric-name');
      var pdfReportId = this.pdfReportId();

      this.refreshMetricChart(elt, metricName, pdfReportId, `-${metricName}`);
    });
  }

  display_chart(canvas_id, ctx, data) {
    this.vchartsLoader.vpsb_bar_chart(ctx, canvas_id, data.barNames, data.values,
                                                     data.unit, data.label,
                                                     'horizontal', false, data.min);
  }
}

$(document).on('turbo:load', function () {
  if ($("body.pdf_reports").length == 0) {
    return;
  }
  console.log("page load in pdf_reports.js");


  var pdfReportPage = new PdfReportsPage();
  pdfReportPage.init();
});
