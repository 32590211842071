// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'jquery'

import '../vpsb/global';
import '../vpsb/layout';
import '../vpsb/all_pages';

import '@selectize/selectize';
window.Popper = require('popper.js').default;
import 'bootstrap';

import '../vpsb/lib/errors_collector';
import '../vpsb/google_analytics';
import '../vpsb/cookie_manager';
import '../vpsb/referral_manager';
import '../vpsb/devise';
import '../vpsb/provider_registration';
import '../vpsb/labs_correlation';
import '../vpsb/plans';
import '../vpsb/accounts_plans_edit';
import '../vpsb/accounts_promotion_reviews';
import '../vpsb/announcements';
import '../vpsb/home';
import '../vpsb/hosters';
import '../vpsb/hoster_filter';
import '../vpsb/trials';
import '../vpsb/trials_index';
import '../vpsb/trials_sysbench';
import '../vpsb/screener';
import '../vpsb/storage_prices';
import '../vpsb/orders';
import '../vpsb/accounts_hoster_profile';
import '../vpsb/accounts_cheap_vps';
import '../vpsb/email_subscription';
import '../vpsb/announcement_bar';
import '../vpsb/vps_instances';
import '../vpsb/public_vps_instance';
import '../vpsb/vps_tasks';
import '../vpsb/vps_index';
import '../vpsb/checkout';
import '../vpsb/product';
import '../vpsb/labs_correlation';
import '../vpsb/best_vps';
import '../vpsb/compare_all';
import '../vpsb/compare_endurance';
import '../vpsb/compare_test_groups';
import '../vpsb/comparison';
import '../vpsb/compare_remote';
import '../vpsb/compare_features';
import '../vpsb/compare_yabs';
import '../vpsb/compare_managed_vps';
import '../vpsb/endurance_run';
import '../vpsb/personal_trials';
import '../vpsb/trial_remote_timing';
import '../vpsb/trial_web_runs';
import '../vpsb/yabs';
import '../vpsb/pdf_reports';
import '../vpsb/hyperscalers';
import '../vpsb/lib/accounts_vert_nav';
import '../vpsb/lib/yabs_table';

// Stylesheets
import '@selectize/selectize/dist/css/selectize.bootstrap4.css';
