import VChartsLoader from './lib/vcharts_loader.js';

class TrialsRemoteTimingPage {
  constructor(data_elt_id, city_canvas_id, time_canvas_id) {
    this.data_elt_id = data_elt_id;
    this.city_canvas_id  = city_canvas_id;
    this.vchartsLoader = new VChartsLoader();
  }

  init() {
    this.vchartsLoader.init();
    this.extract_data();
    this.display_charts();
  }

  display_charts() {
    this.load_trial_remote_timing_chart();
  }

  extract_data() {
    var trial_id_element = $(this.data_elt_id);
    if (trial_id_element.length == 0) {
      console.warn(this.data_elt_id + " is missing from trials remote timing page");
      return;
    }
    console.log("trial_id=" + trial_id_element.data('trial-id'));

    this.trial_id = parseInt(trial_id_element.data('trial-id'));
  }


  load_trial_remote_timing_chart() {
    console.log("load_trial_response_time_chart, canvas_id=" + this.city_canvas_id);

    var canvas = $(this.city_canvas_id);
    var ctx = canvas.get(0).getContext("2d");

    $.ajax({
      type: 'GET',
      url: "/trials/" + this.trial_id + "/remote_timing_data",
      dataType: 'json',
      success: (data) => {
        console.log("success trial remote_timing_data");

        this.vchartsLoader.vpsb_stacked_bar_chart(ctx, data);
      }
    })
  }
}

$(document).on('turbo:load', function() {
  if ($("body.trials.remote_timing").length == 0) {
    return;
  }
  console.log("page load in trial_remote_timing.js");

  var trial_remote_page = new TrialsRemoteTimingPage("#trial_remote_timing", "#trial_remote_timing_chart");
  trial_remote_page.init();
} );
