import VChartsLoader from './lib/vcharts_loader.js';
import { intersectionObserverLazyLoad } from './global';
import { show_layout_modal } from "./global";
import { vpsb_lazy_load_images } from '../vpsb/lazy_loader.js';

class HyperscalersPage {
  constructor() {
    this.vchartsLoader = new VChartsLoader();
  }

  init() {
    this.vchartsLoader.init();
    this.setupLazyLoadCharts();
  }

  setupLazyLoadCharts() {
    intersectionObserverLazyLoad("lazy-chart.chart-canvas", "300px", (container) => {
      this.load_metrics_chart(`#${container.id}`);
    });
    intersectionObserverLazyLoad("lazy-chart.cost-chart-canvas", "300px", (container) => {
      this.load_cost_pie_chart(`#${container.id}`);
    });
    intersectionObserverLazyLoad("lazy-chart.total-prices-chart-canvas", "300px", (container) => {
      this.load_total_prices_chart(`#${container.id}`);
    });
  }

  trial_ids() {
    return $("span#trials-data").data("trial-ids");
  }

  load_metrics_chart(canvasId) {
    var canvas = $(canvasId);
    if (canvas.length == 0) {
      console.error(canvasId + " is missing from hypercalers page");
      return;
    }

    var ctx = canvas.get(0).getContext("2d");
    var metrics = canvas.data('metrics');
    var trial_ids = this.trial_ids();
    $.ajax({
      type: 'GET',
      url: `/labs/hyperscalers_metrics_chart_data/${metrics}`,
      data: { trial_ids: trial_ids },
      dataType: 'json',
      success: (data) => {
        console.log("success hyperscalers metrics charts_data");
        this.vchartsLoader.simple_chart(ctx, 'bar', data, { horizontal: true });
      }
    })
  }

  load_total_prices_chart(canvasId) {
    var canvas = $(canvasId);
    if (canvas.length == 0) {
      console.error(canvasId + " is missing from hypercalers page");
      return;
    }

    var ctx = canvas.get(0).getContext("2d");
    var trial_ids = this.trial_ids();
    $.ajax({
      type: 'GET',
      url: `/labs/hyperscalers_total_prices_chart_data`,
      data: { trial_ids: trial_ids },
      dataType: 'json',
      success: (data) => {
        console.log("success hyperscalers total prices charts_data");
        this.vchartsLoader.simple_chart(ctx, 'bar', data, { horizontal: true });
      }
    })
  }

  load_cost_pie_chart(canvasId) {
    var canvas = $(canvasId);
    if (canvas.length == 0) {
      console.error(canvasId + " is missing from hypercalers page");
      return;
    }

    var ctx = canvas.get(0).getContext("2d");
    var trial_id = canvas.data('trial-id');
    var legend = canvas.data('legend');
    $.ajax({
      type: 'GET',
      url: `/labs/hyperscalers_cost_chart_data/${trial_id}`,
      dataType: 'json',
      success: (data) => {
        console.log("success hyperscalers cost charts_data");
        this.vchartsLoader.simple_pie_chart(ctx, data, { legend_title: legend });
      }
    })
  }

}

$(document).on('turbo:load', function() {
  if ($("body.labs.hyperscalers_simple_use_case").length == 0) {
    return;
  }
  console.log("page load in hyperscalers.js");

  var hyperscalers_page = new HyperscalersPage();
  hyperscalers_page.init();
});

