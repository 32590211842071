function PersonalTrialsShowPage() {
  var plans_other_id = 999999;

  this.init_drop_downs = function() {
    $('#trial_hoster_id option:first').prop("selected", true);
    this.filter_plans_for_hoster();
  }

  this.hoster_id_change_callback = function() {
    var self = this;
    $('#hoster-drop-down-wrapper').change(function() {
      self.filter_plans_for_hoster();
    });
  }

  this.filter_plans_for_hoster = function() {
    var hoster_id = $("#trial_hoster_id").val();
    console.log("Selected: " + hoster_id);
    $('#trial_plan_id :not([hoster_id="' + hoster_id + '"])').hide();
    $('#trial_plan_id [hoster_id="' + hoster_id + '"]').show();
    $('#trial_plan_id [value="' + plans_other_id + '"]').show();
    $('#trial_plan_id').find("option:not(:hidden):eq(0)").prop("selected", true);
  }

  this.plan_id_change_callback = function() {
    $('#plan-drop-down-wrapper').change(function() {
      console.log("Selected plan: " + $("#trial_plan_id").val());
      if ($('#trial_plan_id').val() === plans_other_id.toString()) {
        $('#custom-plan').show();
      } else {
        $('#custom-plan').hide();
      }
    });
  }
}


$(document).on('turbo:load', function() {
  if ($("body.personal_trials.new").length == 0) {
    return;
  }
  console.log("page load in personal_trials.js");

  var trial_page = new PersonalTrialsShowPage();

  trial_page.init_drop_downs();
  trial_page.hoster_id_change_callback();
  trial_page.plan_id_change_callback();
}
);
