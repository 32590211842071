class AccountsHosterProfilePage {
  init() {
    var profile_present = $('#hoster-profile-present').val();
    if (profile_present == 'false' ) {
      $('#profile-create-row').removeClass("d-none");
      $('#profile-show-row').addClass("d-none");
    }
    $('#profile-edit-row').addClass("d-none");

    if ($('#profile-draft').length > 0) {
      var profile_text = $('#profile-draft').val();
      var num_words = profile_text.trim().split(/\s+/).length;
      this.write_num_words(num_words);
    }
  }

  write_num_words(num_words) {
    $('#num-words').html(`${num_words} words`);
    if (num_words >=100) {
      $('#num-words').html(`<span style='color: red'>${num_words} words</span>`);
    }
  }

  setup_num_words_tracker() {
    $('#profile-draft').on('keydown', (event) => {
      var profile_text = $('#profile-draft').val();
      var num_words = profile_text.trim().split(/\s+/).length;
      if (event.which < 0x20 || event.which==65 || event.which==88) {
        return;
      }
      if (num_words >= 100) {
        event.preventDefault();
      }
    });

    $('#profile-draft').on('input', (event) => {
      var profile_text = $('#profile-draft').val();
      var num_words = profile_text.trim().split(/\s+/).length;
      this.write_num_words(num_words);
    });
  }

  setup_save_profile_click() {
    var hoster_id = $('#hoster-id').val();
    var auth_token = $('#auth-token').val();
    $('#save-profile').on('click', () => {
      var profile_draft = $('#profile-draft').val();
      $.ajax({ method: "PUT",
               url: `/api/hosters/${hoster_id}`,
               data: { hoster: { profile_draft: profile_draft }},
               dateType: 'json',
               headers: { "Authorization": `Bearer ${auth_token}` },
               success: () => {
                 console.log("put success");
                 $('#modal-content').html("Your profile was successfully updated.<br/>We'll display it at the top of your public provider page shortly.");
                 $('#profile-update-modal').modal({});
                 $('#publish-profile').prop('disabled', false);
                 this.refresh_profile_show();
                },
               error: function() {
                  console.log("put failure");
                  alert("Failed to update profile");
                }
      });
    });
  }

  setup_publish_profile_click() {
    var hoster_id = $('#hoster-id').val();
    var admin_auth_token = $('#admin-auth-token').val();
    $('#publish-profile').on('click', () => {
      $.ajax({ method: "PUT",
               url: `/api/hosters/${hoster_id}/publish_profile`,
               headers: { "Authorization": `Bearer ${admin_auth_token}` },
               success: (resp, textStatus, xhr) => {
                 console.log(`publish success - ${textStatus} - ${xhr.status}`);
                 $('#modal-content').html(resp);
                 $('#profile-update-modal').modal({});
                 $('#publish-profile').prop('disabled', true);
                },
               error: function(resp, textStatus, xhr) {
                  console.log(`publish failure - code=${xhr.status}`);
                  alert("Failed to publish profile.");
                }
      });
    });
  }

  setup_create_profile_click() {
    $('#create-profile').on('click', () => {
      $('#profile-create-row').addClass("d-none");
      $('#profile-show-row').addClass("d-none");
      $('#profile-edit-row').removeClass("d-none");
    });
  }

  setup_edit_profile_click() {
    $('#edit-profile').on('click', () => {
      $('#profile-create-row').addClass("d-none");
      $('#profile-show-row').addClass("d-none");
      $('#profile-edit-row').removeClass("d-none");
    });
  }

  refresh_profile_show() {
    var hoster_id = $('#hoster-id').val();
    var auth_token = $('#auth-token').val();
    $.ajax({
      url: `/api/hosters/${hoster_id}/profile`,
      method: 'GET',
      headers: { "Authorization": `Bearer ${auth_token}` },
      success: function(profile) {
        console.log("Hoster profile get success");
        $('#profile-show').html(profile);
        $('#profile-show-row').removeClass("d-none");
        $('#profile-edit-row').addClass("d-none");
      },
      error: function() {
        var err = "Hoster profile get request failed";
        alert(err);
        console.log(err);
      }
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.accounts_hosters.edit_profile").length == 0) {
    return;
  }
  console.log("page load in accounts_hoster_profile.js");


  var hosterPage = new AccountsHosterProfilePage();
  hosterPage.init();
  hosterPage.setup_save_profile_click();
  hosterPage.setup_edit_profile_click();
  hosterPage.setup_create_profile_click();
  hosterPage.setup_num_words_tracker();
  hosterPage.setup_publish_profile_click();
});
