import { PriceWeightedSwitch } from './lib/price_weighted_switch.js';

class AccountsPromotionReviews {
}

$(document).on('turbo:load', function() {
  if ($("body.accounts_promotion_reviews.new").length == 0 && $("body.accounts_promotion_reviews.show").length == 0) {
    return;
  }
  console.log("page load in accounts_promotion_reviews.js");

  var priceWeightedSwitch = new PriceWeightedSwitch();
  priceWeightedSwitch.priceWeightedSwitchHandler();

  var accountsPromReviews = new AccountsPromotionReviews();
} );
