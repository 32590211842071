import { setup_referral_click_callbacks } from './referral_manager';

class HosterPlan {
  constructor(planId, trialId) {
    this.planId = planId;
    this.trialId = trialId;
  }

  init() {
    this.priceWeightedSwitchHandler();
  }

  priceWeightedSwitchHandler() {
    $(`#price_weighted${this.planId}`).on('change', (e) => {
      $.ajax({
        url: `/trials/${this.trialId}/grades_table`,
        data: { price_weighted: $(`#price_weighted${this.planId}`).prop('checked')},
        success: (data) => {
          $(`#plan-grades-table-${this.planId}`).html(data);
          var score = $(`#grade-table-${this.planId}`).data('overall-score');
          $(`#plan-score-${this.planId}`).html(score);
        },
        error: () => {
          console.log("grades_table error");
        }
      });
    });
  }
}

class PlansPage {

  constructor() {
    this.selected_plan_filter = $('#plan-filter input[type="radio"]:checked').val();
    this.sort_column = 'hoster';
    this.sort_order = 'asc';
  }

  load_table(sort_column, sort_order, plan_filter) {
    var self = this;
    var url = "/plans/plans_table";
    var params = {};
    params['managed_vps'] = $('#plans-table').data('managed-vps');
    if (typeof sort_column !== 'undefined') {
      params['sort_column'] = sort_column;
      params['sort_order'] = sort_order;
    }
    params['plan_filter'] = plan_filter;

    $.get(url, params, function(data) {
      $("#plans-table").html(data);
      self.on_table_load();
      $('[data-toggle="popover"]').popover();
    }).fail(function() {
      console.error("plans_table async call failed");
    });
  }

  setup_plan_filter_radios() {
    $('#plan-filter').on('change', 'input[type="radio"]', (e) => {
      this.selected_plan_filter = $(e.currentTarget).val();
      this.load_table(this.sort_column, this.sort_order, $(e.currentTarget).val());
    });
  }

  setup_header_callbacks() {
    var self = this;
    $("th").each(function(index) {
      var column_name = $(this).attr('id');
      $(this).on('click', () => {
        self.flip_sort_order(column_name);
        self.sort_column = column_name;
        self.load_table(column_name, self.sort_order, self.selected_plan_filter);
      });
    });
  }

  on_table_load() {
    this.sort_column = $("#plans-table-table").data('sort-column');
    this.sort_order = $("#plans-table-table").data('sort-order');
    this.display_sort_icon();
    this.setup_header_callbacks();
    this.apply_hoster_filter();
    setup_referral_click_callbacks();
  }

  display_sort_icon() {
    var self = this;
    $("th").each(function(index) {
      if ($("#plans-table-table").data('sort-column') === $(this).attr('id')) {
        $(this).append('<i class="fas fa-sort float-right" aria-hidden="true"></i>');
      }
    });
  }

  flip_sort_order(clicked_column_name) {
    if (clicked_column_name === this.sort_column) {
      if (this.sort_order === 'asc') {
        this.sort_order = 'desc';
      } else {
        this.sort_order = 'asc';
      }
    }
  }

  setup_hoster_filter_change() {
    $('#hoster-filter').on("input", () => {
      this.apply_hoster_filter();
    });
  }

  apply_hoster_filter() {
    var pattern = $('#hoster-filter').val();
    $('.plan-row').each( (index, element) => {
      if ( $(element).data('hoster-name').toLowerCase().includes(pattern.toLowerCase())) {
        $(element).removeClass('hidden');
      } else {
        $(element).addClass('hidden');
      }
    });
  }
}

$(document).on('turbo:load', function() {

  if ($("body.plans.show").length > 0) {
    console.log("page load in plans.js (show)");
    $('.price_weighted_switch').each ( (index, element) => {
      var planId = $(element).data('plan-id');
      var trialId = $(element).data('trial-id');
      var hoster_plan = new HosterPlan(planId, trialId);
      hoster_plan.init();
    });
    return;
  }

  if ($("body.plans.index").length == 0 && $("body.plans.managed_vps").length == 0) {
    return;
  }
  console.log("page load in plans.js index/managed_vps");

  var plans_page = new PlansPage();
  plans_page.load_table(plans_page.selected_sort_column, plans_page.sort_order, plans_page.selected_plan_filter);
  plans_page.setup_hoster_filter_change();
  plans_page.setup_plan_filter_radios();
} );
