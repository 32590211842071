import { show_layout_modal, show_multiple_choice_modal } from './global';
import { StripeElements } from './lib/stripe_elements';
import { PayPalCheckout } from './lib/paypal_checkout';

class CheckoutPage {
  order_id() {
    return $('#order-attrs').data('order-id');
  }

  setup_payment_selection_buttons() {
    $('.pay-with-btn').on('click', (event) => {
      event.preventDefault();
      this.hide_payment_selection_buttons();
      var payment_method = $(event.currentTarget).data('payment-method');
      var init_payment_url = $(event.currentTarget).data('init-payment-url');
      var meta = $('meta[name=csrf-token]');
      var csrfToken = meta.attr('content');

      if (payment_method === 'paypal_checkout') {
        $.get(init_payment_url, (data) => {
          var paypal_sdk_url = $('#order-attrs').data('paypal-sdk-url');
          console.log(paypal_sdk_url);
          $.ajaxSetup({ cache: true });
          $.getScript(paypal_sdk_url)
            .done(( script, textStatus ) => {
              console.log("Loaded Paypal SDK successfully");

              $("#payment-checkout-container").html(data);

              var paypalCheckout = new PayPalCheckout();
              paypalCheckout.setup_paypal_buttons(this);
            })
            .fail(( jqxhr, settings, exception ) => {
              console.error("Failed to load paypal SDK");
          });
        })

      } else if (payment_method === 'stripe_elements') {
        $.ajax({ method: "POST",
		             url: init_payment_url,
                 headers: { "X-CSRF-Token": csrfToken },
                 success: (data, textStatus, xhr) => {
                    console.log(`Success from ${init_payment_url}`);
                    $("#payment-checkout-container").html(data);
                    var stripe_elts = new StripeElements('#stripe-elements-attrs');
                    stripe_elts.initialize();
                    $('.pay-with-btn').addClass('d-none');
                  },
                  error: (data, textStatus, xhr) => {
                    console.error(data.responseJSON.error);
                    show_layout_modal(`Could not create subscription: ${data.responseJSON.error}`);
                  }
                });

      } else {
      };
    });
  }

  setup_back_button() {
    $('#back-btn').on('click', (e) => {
      e.preventDefault();

      this.cancel_payment();

      $('#payment-selection-buttons').removeClass('d-none');
      $('#back-from-payment').addClass('d-none');
      $('#payment-checkout-container').html('');
    });
  }

  hide_payment_selection_buttons() {
    $('#payment-selection-buttons').addClass('d-none');
    $('#back-from-payment').removeClass('d-none');
  }

  cancel_payment() {
    var cancel_url = $('#order-attrs').data('payment-cancel-url');
    $.ajax({ method: "GET",
      url: cancel_url,
      dataType: 'json',
      success: function(data) {
        console.log(data.message);
      },
      error: function(data) {
        console.error(`code: ${data.status}`);
      }
    });
  }

  cancel_subscription() {
    $(this.clicked_cancel_elt).attr('disabled', true);
    var meta = $('meta[name=csrf-token]');
    var csrfToken = meta.attr('content');
    var cancel_url = $(this.clicked_cancel_elt).data('cancel-subscription-url');

    console.debug(`cancel_subscription`, cancel_url);

    $.ajax({
      method: 'DELETE',
      url: cancel_url,
      headers: {
        'X-CSRF-Token': csrfToken
      },
      dataType: 'json',
      success: function(data, textStatus, xhr) {
        console.log("cancel successful - showing modal");
        show_layout_modal("Your payment subscription was successfully cancelled.", data.redirect_to);
        this.clicked_cancel_elt = undefined;
      },
      error: function(data, textStatus, xhr) {
        var respBody = data.responseJSON;
        show_layout_modal(respBody.error);
        $(cancel_elt).attr('disabled', false);
      }
    });
  }

  setup_cancel_subscription() {
    console.debug("setup_cancel_subscription");
    $('.cancel-subscription-btn').on('click', (e) => {
      e.preventDefault();

      this.clicked_cancel_elt = e.currentTarget;

      show_multiple_choice_modal("Are you sure? After the payment susbcription is cancelled, you will receive monthly invoices that you will have to pay manually.", "Yes", "No",
        this.cancel_subscription.bind(this),
        null
      );
    });
  }

  setup_stripe_customer_portal() {
    if ($('.stripe-portal-btn').length == 0) {
      return;
    }

    $('.stripe-portal-btn').on('click', (e) => {
      e.preventDefault();
      var meta = $('meta[name=csrf-token]');
      var csrfToken = meta.attr('content');
      $.ajax({
        method: 'POST',
        url: $(e.currentTarget).data('create-portal-session-url'),
        headers: { 'X-CSRF-Token': csrfToken },
        data: { flow_type: $(e.currentTarget).data('flow-type') },
        dataType: 'json',
        success: function(data) {
          console.log(`create portal session successful - redirecting to ${data.redirect_to}`);
          location.href = data.redirect_to;
        },
        error: function(data) {
          var respBody = data.responseJSON;
          show_layout_modal(respBody.error);
        }
      });
    });
  }

  cancel_order() {
    var meta = $('meta[name=csrf-token]');
    var csrfToken = meta.attr('content');
    $.ajax({
      method: 'DELETE',
      url: $('#order-attrs').data('cancel-order-url'),
      headers: {
        'X-CSRF-Token': csrfToken
      },
      dataType: 'json',
      success: function(data, textStatus, xhr) {
        console.log("cancel successful - showing modal");
        show_layout_modal("Your order was cancelled.", data.redirect_to);
      },
      error: function(data, textStatus, xhr) {
        var respBody = data.responseJSON;
        show_layout_modal(respBody.error);
      }
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.payments.new").length == 0 && $("body.accounts_hosters.manage_stripe").length == 0) {
    return;
  }
  console.log("page load in checkout.js (checkout page)");

  var checkoutPage = new CheckoutPage();
  checkoutPage.setup_payment_selection_buttons();
  checkoutPage.setup_back_button();
  checkoutPage.setup_cancel_subscription();
  checkoutPage.setup_stripe_customer_portal();

  $(document).ajaxStart(function() {
    $('#payment-spinner').removeClass('hidden');
  }).ajaxStop(function() {
    $('#payment-spinner').addClass('hidden');
  });
});

$(document).on('turbo:load', function() {
  if ($("body.payments.success").length == 0) {
    return;
  }
  console.log("page load in checkout.js (success page)");

  $('#refresh-payment-btn').on('click', (e) => {
    e.preventDefault();
    $.get($(e.currentTarget).data('status-url')).
      done( (data) => {
        $('#payment-status-container').html(data);
      })
  });
});

