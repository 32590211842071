import { show_layout_modal, setupAjaxCursor } from '../vpsb/global';
import { SelectizeMultipleSelect } from "./lib/selectize_multiple_select";

class PublicVpsInstance {
  init() {
    this.setup_test_connection();
    this.radio_divs_click_handler();
    this.setup_hoster_plan_select();
  }

  radio_divs_click_handler() {
    $('#vps_instance_choice_auth_password_div').on('click', (event) => {
      console.debug("click on vps_instance_auth_password");
      $('input:radio#vps_instance_choice_auth_password').prop('checked', true);
    });

    $('#vps_instance_choice_auth_sshkey_div').on('click', (event) => {
      console.debug("click on vps_instance_auth_sshkey");
      $('input:radio#vps_instance_choice_auth_sshkey').prop('checked', true);
    });
  }

  setup_test_connection() {
    var form = $('#new_vps_instance');
    var meta = $('meta[name=csrf-token]');
    var csrfToken = meta.attr('content');
    $('#test-connection-btn').on('click', (event) => {
      event.preventDefault();
      $.ajax({
        method: "POST",
        url: "/vps_instances/test_connection",
        data: form.serialize(),
        dataType: 'json',
        headers: { "X-CSRF-Token": csrfToken },
        success: function(data) {
            show_layout_modal(data.data);
        },
        error: function(data, textStatus, xhr) {
          var respBody = data.responseJSON;
          console.log(`err: ${respBody.error}, code: ${data.status}`);
          show_layout_modal(respBody.data);
        }
      });
    })
  }

  setup_hoster_plan_select() {
    var selectizeBox = new SelectizeMultipleSelect("#vps_instance_plan_id", 'Type provider or plan names', 'plans');
    selectizeBox.init_select();
    selectizeBox.setup_select_changes();
  }
}

$(document).on('turbo:render', function() {
  if ($("body.vps_instances.create_public").length == 0) {
    return;
  }
  console.log("page render in public_vps_instance.js");

  var vps_instances = new PublicVpsInstance();
  vps_instances.init();

  setupAjaxCursor();
});

$(document).on('turbo:load', function() {
  if ($("body.vps_instances.new_public").length == 0) {
    return;
  }
  console.log("page load in public_vps_instance.js");

  var vps_instances = new PublicVpsInstance();
  vps_instances.init();

  setupAjaxCursor();
});
