export class PriceWeightedSwitch {
  priceWeightedSwitchHandler() {
    $('#price_weighted').on('change', (e) => {
      var trialId = $('#plan-grades-table').data('trial-id');
      var planId = $('#plan-grades-table').data('plan-id');
      var price_weighted = $('#price_weighted').prop('checked');
      $.ajax({
        url: `/trials/${trialId}/grades_table`,
        data: { price_weighted: price_weighted },
        success: (data) => {
          $('#plan-grades-table').html(data);
          var score = $(`#grade-table-${planId}`).data('overall-score');
          console.log("grades_table success, score=" + score);
          $(`#plan-score-${planId}`).html(score);
          if (price_weighted) {
            $(`#pw-score-label-${planId}`).removeClass('d-none');
          } else {
            $(`#pw-score-label-${planId}`).addClass('d-none');
          }
        },
        error: () => {
          console.log("grades_table error");
        }
      });
    });
  }
}
