import { EditTable } from "./lib/edit_table.js";

$(document).on('turbo:load', function() {
  if ($("body.accounts_plans.edit").length == 0) {
    return;
  }
  console.log("page load in plans_edit.js");

  var plans_edit_page = new EditTable(true, '/accounts/plans');
  plans_edit_page.init();
} );
