import CookieManager from './cookie_manager';
import { vpsb_lazy_load_images } from '../vpsb/lazy_loader.js';
import { vpsb_insert_human_token } from './global';

function vpsbIncrementPagesInSession(cookies) {
  var numPages = cookies.get('numPagesInSession');
  if (typeof numPages !== 'undefined') {
    numPages++;
  } else {
    numPages = 1;
  }

  cookies.set('numPagesInSession', numPages, 1);
}

function themeColor() {
  return $('html').attr('data-theme');
}

function postPageview() {
  var meta_csrf = $('meta[name=csrf-token]');
  if (meta_csrf.length == 0) {
    return;
  }

  // var meta_env = $('meta[name=environment]');
  // if (meta_env.length > 0 && meta_env.attr("content") === "development") {
  //   return;
  // }

  const ignoreMe = localStorage.getItem("ignoreMe");
  if (ignoreMe === 'true') {
    return;
  }

  var advertiser = $(".sidebar-square-image").data("advertiser");

  var stats = {
    advertiser: advertiser,
    num_vpsb_ad_unit: $('#vpsb-square-image').length,
    num_codefund_ad_unit: 0,
    theme_color: themeColor(),
  };

  const firstVisitTimestamp = localStorage.getItem("firstVisitTimestamp");
  const previousVisitTimestamp = localStorage.getItem("previousVisitTimestamp");
  if (previousVisitTimestamp) {
    stats.previous_visit_timestamp_ms = previousVisitTimestamp;
  }
  if (firstVisitTimestamp) {
    stats.first_visit_timestamp_ms = firstVisitTimestamp;
  }

  var csrfToken = meta_csrf.attr('content');
  $.ajax({ method: "POST",
         url: `/api/pageviews`,
         data: stats,
         dataType: 'json',
         headers: { "X-CSRF-Token": csrfToken },
  });
}

function csrfToken() {
  var meta = $('meta[name=csrf-token]');
  if (meta.length == 0) {
    return "";
  }
  return meta.attr('content');
}

function logAdClick() {
  $('#vpsb-square-image').on('click', (event) => {
    $.ajax({ method: "POST",
           url: `/api/pageviews/ad_click`,
           data: { advertiser: $(event.currentTarget).data('advertiser') },
           headers: { "X-CSRF-Token": csrfToken() }
    });
  });
}

function setupNavbarSignOut() {
  $('.navbar').on('click', 'a[data-method="delete"]', (e) => {
    e.preventDefault();
    $.ajax({
      method: "DELETE",
      url: $(e.currentTarget).attr('href'),
      headers: { "X-CSRF-Token": csrfToken() },
      success: () => {
        Turbo.visit('/');
      },
      error: () => {
        console.error("Sign out failed");
      }
    });
  });
}

function moveSidebarAd() {
  if ($(window).width() >= 990)
    return;

  if ($("#mobile-square-ad-wrapper").length == 0 || $("#vpsb-square-image").length == 0)
    return;

  $("#mobile-square-ad-wrapper").empty();
  $("#vpsb-square-image").appendTo("#mobile-square-ad-wrapper");
}

function plausiblePageview() {
  if ($('meta[name=environment]').attr('content') !== 'development') {
    plausible('pageview');
  }
}

function setupColorSchemeToggle() {
  $('#colorScheme').on('click', 'label', (e) => {
    e.stopPropagation();
  });

  $('#colorScheme').on('change', 'input[type="radio"]', (e) => {
    console.log("Color scheme changed to " + $(e.currentTarget).val());
    if ($(e.currentTarget).val() === 'dark') {
      localStorage.setItem('theme', 'dark');
      setDarkColorTheme();
    } else if ($(e.currentTarget).val() === 'light') {
      localStorage.setItem('theme', 'light');
      setLightColorTheme();
    } else if ($(e.currentTarget).val() === 'auto') {
      localStorage.setItem('theme', 'system');
      $('nav.navbar').removeClass(['navbar-light'])
      $('.sidebar').removeClass(['theme-light', 'theme-dark']);
      setSystemColorTheme();
    }
  });
}

function setupSystemColorChange() {
  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setDarkColorTheme();
    } else {
      setLightColorTheme();
    }
  });
}

function loadColorThemeFromStorage() {
  var theme = localStorage.getItem('theme');
  if (theme === 'dark') {
    setDarkColorTheme();
    $('#colorScheme input[value="dark"]').prop('checked', true);
  } else if (theme === 'light') {
    setLightColorTheme();
    $('#colorScheme input[value="light"]').prop('checked', true);
  } else {
    setSystemColorTheme();
    $('#colorScheme input[value="auto"]').prop('checked', true);
  }
}

function setSystemColorTheme() {
  if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    setDarkColorTheme();
  } else {
    setLightColorTheme();
  }
}

function setLightColorTheme() {
  $('html').attr('data-theme' , 'light');
  $('nav.navbar').removeClass(['navbar-dark']).addClass(['navbar-light'])
  $('.sidebar').addClass('theme-light').removeClass('theme-dark');
}

function setDarkColorTheme() {
  $('html').attr('data-theme' , 'dark');
  $('nav.navbar').removeClass(['navbar-light']).addClass(['navbar-dark'])
  $('.sidebar').addClass('theme-dark').removeClass('theme-light');
}

function saveVisitTimestamps() {
  if (typeof(Storage) === "undefined") {
    return;
  }

  const existingTimestamp = localStorage.getItem("firstVisitTimestamp");
  const currentTimestamp = new Date().getTime();
  localStorage.setItem("previousVisitTimestamp", currentTimestamp);
  if (!existingTimestamp) {
    localStorage.setItem("firstVisitTimestamp", currentTimestamp);
  }
}

$(document).on('turbo:load', function() {
  var cookies = new CookieManager();
  vpsbIncrementPagesInSession(cookies);
  moveSidebarAd();
  vpsb_lazy_load_images();
  setupNavbarSignOut();
  vpsb_insert_human_token('.email-subscription-form');
  vpsb_insert_human_token('.vpsb-square-ad-form');
  setupColorSchemeToggle();
  setupSystemColorChange();
  loadColorThemeFromStorage();

  history.scrollRestoration = 'auto';

  setTimeout(() => {
    plausiblePageview();
    postPageview();
    saveVisitTimestamps();
  }, 250);
});
