import { vpsb_insert_human_token } from './global';

class HomePage {
  setInitialBestVpsButtonState() {
    var visiblePrice = $('.hiding-wrapper').not(".hidden").data('max-price');
    $(`input[value=${visiblePrice}]`, '.best-vps #button-form').prop("checked", true);
  }

  setupBestVpsButtons() {
    $('input', '.best-vps #button-form').on('change', (event) => {
      event.preventDefault();
      var checkedBtn = $('input[name=price_range_btn]:checked', '#button-form');
      var checkedMaxPrice = checkedBtn.val();

      var previousBvps;
      var nextBvps;
      $('.hiding-wrapper').each( (index, element) => {
        if ($(element).data('max-price').toString() === checkedMaxPrice) {
          nextBvps = element;
        } else if (!$(element).hasClass('hidden')) {
          previousBvps = element;
        }
      });

      $(previousBvps).fadeOut(200, () => {
        $(previousBvps).addClass('hidden');
        $(nextBvps).hide();
        $(nextBvps).removeClass('hidden');
        $(nextBvps).fadeIn(400);
      });
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.home.index").length == 0 && $("body.home.contact").length == 0) {
    return;
  }
  console.log("page load in home.js");

  var homePage = new HomePage();
  homePage.setInitialBestVpsButtonState();
  homePage.setupBestVpsButtons();

  vpsb_insert_human_token('#contact-form');
} );
