import { LoginModal } from '../vpsb/login_modal';
import VChartsLoader from './lib/vcharts_loader.js';
import { YabsTable, YabCards } from './lib/yabs_table';
import { CompareYabsPage } from './compare_yabs';
import { intersectionObserverLazyLoad } from './global';

class YabsPage {
  constructor() {
    this.vchartsLoader = new VChartsLoader();
  }

  init() {
    this.vchartsLoader.init();
    this.setupLazyLoadCharts();
    this.setupToggleRawOutput();
  }

  setupLazyLoadCharts() {
    intersectionObserverLazyLoad("lazy-chart", "300px", (container) => {
      this.load_bar_chart(`#${container.id}`);
    });
  }

  load_bar_chart(canvasId) {
    var canvas = $(canvasId);
    if (canvas.length == 0) {
      console.error(canvas_id + " is missing from yabs page");
      return;
    }

    var ctx = canvas.get(0).getContext("2d");
    var permalink = canvas.data('yab-id');
    var test_name = canvas.data('test-name');
    $.ajax({
      type: 'GET',
      url: `/yabs/${permalink}/chart_data/${test_name}`,
      data: { },
      dataType: 'json',
      success: (data) => {
        console.log("success yabs charts_data");
        this.display_bar_chart(ctx, data);
      }
    })
  }

  display_bar_chart(ctx, data) {
    this.vchartsLoader.vpsb_bar_chart_improved(ctx, data);
  }

  setupToggleRawOutput() {
    $("#toggle-raw-output").on('click', (e) => {
      e.preventDefault();
      $("#yab-raw-output").toggleClass("d-none");

      var buttonText = $("#toggle-raw-output").text();
      if (buttonText.match(/Show/)) {
        $("#toggle-raw-output").text(buttonText.replace('Show', 'Hide'));
      } else {
        $("#toggle-raw-output").text(buttonText.replace('Hide', 'Show'));
      }
    });
  }
}

class NewYabPage {
  setup_form_submit(formElt) {
    $(formElt).on('submit', (event) => {
      var loggedIn = $(formElt).data('logged-in');
      if (loggedIn) {
        console.log("Skipping login modal");
        return;
      }

      event.preventDefault();
      var loginModal = new LoginModal(formElt, { show_skip_button: true, skip_button_label: "Skip Sign In" });
      var msg = "To link this new yab to your account and manage it, you must sign in or sign up now. If you don't need ownership of this yab, press the Skip button.";
      loginModal.load(msg);
      return false;
    });
  }
}

$(document).on('turbo:load', function() {
  var yabs_table;
  if ($("#yabs-list-container").length > 0) {
    console.log("Loading YabsTable");
    yabs_table = new YabsTable();
    yabs_table.init();
  }

  if ($("body.compare_yabs.index").length > 0) {
    console.log("page load in compare yabs");
    var compare_yabs = new CompareYabsPage(yabs_table);
    compare_yabs.init();
  }

  if ($("body.yabs.show").length > 0) {
    var yabs_page = new YabsPage();
    yabs_page.init();
  }

  if ($("body.yabs.new").length > 0 || $("body.yabs.review").length > 0) {
    var new_yabs_page = new NewYabPage();
    new_yabs_page.setup_form_submit('#yab-form');
  }

  if ($(".yab-polar-chart").length > 0) {
    console.log("Loading YabCards");
    var yabCards = new YabCards(true);
    yabCards.init();
  }
} );
