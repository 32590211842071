export class EditTablePagination {
  disable_link(id) {
    var element = $('#'+id);
    var class_val = element.attr('class');
    if(class_val.indexOf('disabled') == -1){
      element.attr('class', class_val + ' disabled');
    }
  }

  enable_link(id) {
    var element = $('#'+id);
    var class_val = element.attr('class');
    if (class_val.indexOf('disabled') >= 1){
      class_val = class_val.replace('disabled', '');
      element.attr('class', class_val);
    }
  }

  update_buttons_state() {
    var element = $('#edit-table');
    var curr_page = parseInt(element.data("curr-page"));
    var total_pages = parseInt(element.data("total-pages"));
    if (curr_page == 1) {
      this.disable_link('prev-btn');
      this.enable_link('next-btn');

      if (total_pages == 1) {
        this.disable_link('prev-btn');
        this.disable_link('next-btn');
      }
    } else {
      this.enable_link('prev-btn');
      if (curr_page == total_pages) {
        this.disable_link('next-btn');
      } else {
        this.enable_link('next-btn');
      }
    }
    $('#pages').html("Showing " + curr_page + " out of " + total_pages + " pages");
  }

  setup_pagination_callbacks(load_table) {
    $('#prev-btn').on('click', (e) => {
      e.preventDefault();
      var pattern =  $('#edit-table').data("pattern");
      var curr_page = $('#edit-table').data("curr-page");
      curr_page = parseInt(curr_page);
      load_table(pattern, curr_page-1);
    });

    $('#next-btn').on('click', (e) => {
      e.preventDefault();
      var pattern =  $('#edit-table').data("pattern");
      var curr_page = $('#edit-table').data("curr-page");
      curr_page = parseInt(curr_page);
      load_table(pattern, curr_page+1);
    });
  }
}
