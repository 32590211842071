import { vpsb_deselect_all_checkboxes, show_layout_modal, intersectionObserverLazyLoad } from './global';

class CompareFeaturesPage {
  check_num_checked_boxes() {
    $(".features_provider_box").change(function() {
      if(this.checked) {
        if ($("#features_provider_form input:checkbox:checked").length > 4) {
          show_layout_modal("No more than 4 providers in comparison please!");
          $(this).prop("checked", false);
        }
      }
    });
  }

  setup_popovers() {
    intersectionObserverLazyLoad("table-with-popovers", "80px", (container) => {
      $(`a[data-toggle="popover"]`, container).popover();
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.compare_features.index").length == 0 && $("body.compare_features.show").length == 0) {
    return;
  }
  console.log("page load in compare_features.js");

  var compare_features_page = new CompareFeaturesPage();

  compare_features_page.check_num_checked_boxes();
  compare_features_page.setup_popovers();

  vpsb_deselect_all_checkboxes("#uncheck-all", "#features_provider_form");
} );
