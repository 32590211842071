import { vpsb_insert_human_token } from './global';

class ProviderSignUpPage {
  submit_click_handler() {
    $('#sign-up-submit').on('click', (event) => {
      if ($('#user_provider_name').val() == '') {
        event.preventDefault();
        alert('Provider name must be set.');
      }

      if (!$('#user_privacy_policy').is(':checked')) {
        event.preventDefault();
        alert('Sorry, we can\'t record your email address if you don\'t approve our privacy policy.');
      }
      if (!$('#user_terms_of_service').is(':checked')) {
        event.preventDefault();
        alert('Sorry, we can\'t create your account unless you approve our terms_of_service.');
      }
    });
  }
}

$(document).on('turbo:render', function() {
  // Hanle 422 response on form submit error (turbo:load does not fire on 4xx)
  if ($("body.registrations.create").length == 0) {
    return;
  }
  console.log("page render in provider_registration.js");

  var provider_sign_up_page = new ProviderSignUpPage();
  vpsb_insert_human_token('#new_user');
  provider_sign_up_page.submit_click_handler();
});

$(document).on('turbo:load', function() {
  if ($("body.registrations.new_provider").length == 0 && $("body.registrations.new").length == 0 && $("body.registrations.create").length == 0 && $("body.registrations.new_provider_claim").length == 0) {
    return;
  }
  console.log("page load in provider_registration.js");

  var provider_sign_up_page = new ProviderSignUpPage();
  vpsb_insert_human_token('#new_user');
  provider_sign_up_page.submit_click_handler();
});
