import { log_referral_click } from './referral_manager';

class AnnouncementsPage {
  setupBodyLinksClick() {
    $('.announcement-body a').on('click', (e) => {
      var t = $(e.currentTarget);

      if (t.data('no_prevent_default') !== true) {
        e.preventDefault();
        console.debug(`click announcement link logging referral`);
        var hoster_id = $(e.target).parents('.announcement-container').data('hoster-id');
        var origin = 'announcements';

        log_referral_click(hoster_id, window.location.pathname, origin);
        t.data('no_prevent_default', true);
        t.attr('target', '_blank');
        t[0].click();
      } else {
        console.debug(`click announcement link navigating to target in new tab`);
      }
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.announcements").length == 0) {
    return;
  }
  console.log("page load in announcements.js");

  var announcePage = new AnnouncementsPage();
  announcePage.setupBodyLinksClick();
});