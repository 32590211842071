import VChartsLoader from './lib/vcharts_loader.js';
import { intersectionObserverLazyLoad } from './global';
import PlansSelect from './plans_select';

class ComparisonPage {
  constructor(pageName) {
    this.vchartsLoader = new VChartsLoader();
    this.pageName = pageName;

    console.log(`page load in comparison.js:${this.pageName}`);
  }

  init() {
    this.setupLazyLoadCharts();
    this.initPlansSelect();
  }

  setupLazyLoadCharts() {
    intersectionObserverLazyLoad("lazy-chart", "300px", (container) => {
      this.load_chart($(`#${container.id}`));
    });
  }

  initPlansSelect() {
    this.plansSelect = new PlansSelect(this.pageName);
  }

  load_chart(canvas) {
    if (canvas.length == 0) {
      console.error(canvas.id + ` is missing from compare ${this.pageName} page`);
      return;
    }

    var test_type = canvas.data('test-type');
    var test_name = canvas.data('test-name');
    var metric_names = canvas.data('metric-names');
    var selected_plan_ids = canvas.data('selected-plan-ids');

    var ctx = canvas.get(0).getContext("2d");

    $.ajax({
      type: 'GET',
      url: `/compare/performances/${this.pageName}/chart_data?metric_names=` + metric_names +
      '&test_type=' + test_type +
      '&test_name=' + test_name +
      '&selected_plan_ids=' + encodeURIComponent(selected_plan_ids),
      data: { },
      dataType: 'json',
      success: (data) => {
        console.log("success new charts_data");
        this.display_chart(ctx, data, test_type, test_name, data.unit, data.unit);
      }
    })
  }

  display_chart(ctx, data, title, subtitle, unit, short_unit) {
    this.vchartsLoader.vpsb_bar_chart_improved(ctx, data);
  }
}

$(document).on('turbo:load', function() {
  if ($("body.compare_sysbench.index").length > 0 || $("body.compare_sysbench.show").length > 0) {
    var comparison_page = new ComparisonPage('sysbench');
    comparison_page.init();
    console.log("ComparisonPage sysbench loaded");
    return;
  }

  if ($("body.compare_web_runs.index").length > 0 || $("body.compare_web_runs.show").length > 0) {
    var comparison_page = new ComparisonPage('web_runs');
    comparison_page.init();
    console.log("ComparisonPage web runs loaded...");
    return;
  }

  if ($("body.compare_network_transfers.index").length > 0 || $("body.compare_network_transfers.show").length > 0) {
    var comparison_page = new ComparisonPage('network_transfers');
    comparison_page.init();
    return;
  }

  if ($("body.compare_geekbench.index").length > 0 || $("body.compare_geekbench.show").length > 0) {
    var comparison_page = new ComparisonPage('geekbench');
    comparison_page.init();
    return;
  }
} );
