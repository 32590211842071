function UsersSignUpPage() {

  this.submit_click_handler = function() {
    $('#sign-up-submit').click(function(event) {
      if ($('#user_provider_name').val() == '') {
        event.preventDefault();
        alert('Provider name must be set.');
      }

      if (!$('#user_privacy_policy').is(':checked')) {
        event.preventDefault();
        alert('Sorry, we can\'t record your email address if you don\'t approve our privacy policy.');
      }
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.devise_registrations.new").length == 0 &&
      $("body.devise_registrations.create").length == 0) {
    return;
  }
  console.log("page load in devise.js");

  var users_sign_up_page = new UsersSignUpPage();
  users_sign_up_page.submit_click_handler();
});
