import { show_layout_modal, intersectionObserverLazyLoad } from '../global';
import VChartsLoader from './vcharts_loader.js';

export class YabCards {
  constructor() {
    this.vchartsLoader = new VChartsLoader();
    this.vchartsLoader.init();
    this.permalinks_queue = [];
    this.chart_throttle_delay = 100;
  }

  init() {
    this.setupLazyLoadCharts();
  }

  setupLazyLoadCharts() {
    var observed_class = "lazy-polar";
    intersectionObserverLazyLoad(observed_class, "100px", (container) => {
      console.debug("intersectionObserver fired for", $(container).data('yab-id'));
      var all_permalinks = [];
      if ($(container).data('drawn') == false) {
        $(container).data('drawn', 'drawing');
        all_permalinks.push($(container).data('yab-id'));
      }

      var main_idx = $(container).data('idx');

      var other_canvases = $(`canvas.${observed_class}`);
      var num_added = 0;
      other_canvases.each((index, cv) =>  {
        if (num_added >= 5)
          return false;

        if ($(cv).data('drawn') == false && Math.abs(main_idx - $(cv).data('idx')) < 4) {
          all_permalinks.push($(cv).data('yab-id'));
          $(cv).data('drawn', 'drawing');
          num_added++;
        }
      });
      var unique_permalinks = [...new Set(all_permalinks)];
      if (unique_permalinks.length > 0) {
        this.load_polar_charts_with_throttle(unique_permalinks, this.chart_throttle_delay);
        this.chart_throttle_delay = 400;
      }
    });
  }

  load_polar_charts_with_throttle(permalinks, delay) {
    if (this.chartsTimerId) {
      clearTimeout(this.chartsTimerId);
    }

    this.permalinks_queue = [...new Set(this.permalinks_queue.concat(permalinks))];

    this.chartsTimerId = setTimeout(() => {
      this.load_polar_charts(this.permalinks_queue);
      this.chartsTimerId = undefined;

      this.permalinks_queue = [];
    }, delay);
  }

  load_polar_charts(permalinks) {
    console.debug("load_polar_charts", permalinks);

    $.ajax({
      type: 'GET',
      url: `/yabs/polar_charts_data/${permalinks.join(',')}`,
      data: { preferred_geekbench_version: this.preferred_geekbench_version },
      dataType: 'json',
      success: (data) => {
        console.log("success yabs polar_charts_data");

        for (var permalink in data.series) {
          var canvas_name = "canvas[data-yab-id='" + permalink + "']";
          var canvas = $(canvas_name);
          if (canvas.length == 0) {
            console.error(canvas_name, "is missing from yabs page.");
            continue;
          }

          var serie = data.series[permalink];
          var name_and_dataset = {}
          name_and_dataset[serie.yab_name] = serie.dataset;
          var single_chart_data = {
            labels: serie.labels,
            series: name_and_dataset,
            axer: data.axer
          };

          var ctx = canvas;
          canvas.data('drawn', 'true');

          this.vchartsLoader.vpsb_polar_chart(ctx, single_chart_data, canvas);
        }
      }
    });
  }

  preferred_geekbench_version() {
    if ($('#yab-data-container').length == 0) {
      throw new Error('Missing yab-data-container element');
    }
    return $('#yab-data-container').data('preferred-geekbench-version');
  }
}

export class YabsTable {
  init() {
    console.log("YabsTable init");
    this.resize_table_container();
    if ($('.yabs-list').length===0 ) {
      this.load_list($("#yabs-table").data('sort-column'), $("#yabs-table").data('sort-order'));
    }
    this.setup_hoster_filter();
    this.setup_sort_column_callbacks();
    this.setup_private_checkboxes();
    this.setup_verified_only_checkbox();
    this.setup_destroy_buttons();
    this.setup_edit_buttons();
    this.setup_pagination_buttons();

    $(window).on('resize', () => {
      this.resize_table_container();
    });
  }

  resize_table_container() {
    var table_container_height = parseInt($('#yabs-list-container').css('height'));
    var resize_ok = $('#yabs-list-container').data('resize-ok');
    if (resize_ok !== true) {
      return;
    }

    var table_container_width = parseInt($('#yabs-list-container').css('width'));
    var new_height = 500 * ( 700 / table_container_width);
    if (table_container_width < 500) {
      new_height = 800;
    } else if (table_container_width >= 500 && table_container_width < 720) {
      new_height = 720;
    } else if (table_container_width >= 720) {
      new_height = 580;
    }

    if (table_container_height !== new_height) {
      console.debug('Resizing table container height, new height=', new_height, " width=", table_container_width);
      $('#yabs-list-container').css('height', `${new_height}px`);
    }
  }

  load_list_with_throttle(sort_column, sort_order, delay) {
    if (this.hosterFilterTimerId) {
      clearTimeout(this.hosterFilterTimerId);
    }

    this.hosterFilterTimerId = setTimeout(() => {
      this.load_list(sort_column, sort_order);
      this.hosterFilterTimerId = undefined;
    }, delay);
  }

  load_list(sort_column, sort_order) {
    var page_num = $('#yabs-list-container #yabs-table').data('page-num');
    var by_hoster_id = $('#yabs-list-container').data('by-hoster-id');
    var show_table = $('#yabs-list-container').data('show-table');
    var hoster_filter = $('#hoster-filter').val();
    var verified_only = $('#verified_only').is(":checked");
    var params = { hoster_filter: hoster_filter,
                   sort_order: sort_order,
                   sort_column: sort_column,
                   by_hoster_id: by_hoster_id,
                   page_num: page_num,
                   verified_only: verified_only,
                   show_table: show_table,
                   excluded_yab_ids: $('#yabs-selected-table-container').data('selected-ids') };

    var table_url = $('#yabs-list-container').data('yabs-table-path');
    $.get(table_url, params, (data) => {
      $("#yabs-list-container").html(data);
      if (show_table == true) {
        this.display_sort_icon();
      } else {
        var yabCards = new YabCards(true);
        yabCards.init();
      }

    }).fail( () => {
      console.error("load_list async call failed");
    });
  }

  setup_sort_column_callbacks() {
    $('#yabs-list-container').on('click', "#yabs-table th", (event) => {
      var column_name = $(event.currentTarget).attr('id');
      var sort_order = 'asc';
      if ($("#yabs-table").data('sort-order') === 'asc') {
        sort_order = 'desc';
      }
      this.load_list(column_name, sort_order);
    });
  }

  display_sort_icon() {
    $("#yabs-list-container th").each(function(index) {
      if ($("#yabs-table").data('sort-column') === $(this).attr('id')) {
        $(this).append('<i class="fas fa-sort float-right" aria-hidden="true"></i>');
      }
    });
  }

  setup_hoster_filter() {
    $('#hoster-filter').on("input", () => {
      $('#yabs-list-container #yabs-table').data('page-num', 0)
      this.load_list_with_throttle($("#yabs-table").data('sort-column'),
                                    $("#yabs-table").data('sort-order'),
                                    400);
    });
  }

  setup_verified_only_checkbox() {
    $('#verified_only').on("change", (event) => {
      $('#yabs-list-container #yabs-table').data('page-num', 0)
      this.load_list($("#yabs-table").data('sort-column'), $("#yabs-table").data('sort-order'));
    });
  }

  setup_private_checkboxes() {
    $('#yabs-list-container').on('change', 'input.private-checkbox', (event) => {
      var yab_id = $(event.currentTarget).parents('tr.yab-row').data('yab-id');
      $.ajax({ method: "PUT",
        url: `/accounts/yabs/${yab_id}`,
        contentType: "application/json",
        data: JSON.stringify({ private: $(event.currentTarget).is(":checked") }),
        dataType: 'json',
      success: function(data) {
        console.log(`private checkbox put success`);
        show_layout_modal(data.message);
       },
      error: function(data) {
       var json = data.responseJSON;
       console.error(`err: ${json.errors}, code: ${data.status}`);
       show_layout_modal(json.errors.join(', '));
       }
      });
    });
  }

  setup_destroy_buttons() {
    $('#yabs-list-container').on('click', 'a.destroy-button', (event) => {
      event.preventDefault();
      var confirm = window.confirm('Are you sure? This cannot be undone.');
      if (!confirm) {
        return;
      }

      var yab_id = $(event.currentTarget).parents('tr.yab-row').data('yab-id');
      $.ajax({ method: "DELETE",
        url: `/accounts/yabs/${yab_id}`,
        dataType: 'json',
      success: function(data) {
        console.log(`destroy success`);
        show_layout_modal(data.message);
        $(event.currentTarget).parents('tr.yab-row').remove();
       },
      error: function(data) {
       var json = data.responseJSON;
       console.error(`err: ${json.errors}, code: ${data.status}`);
       show_layout_modal(json.errors.join(', '));
       }
      });
    });
  }

  setup_edit_buttons() {
    $('#yabs-list-container').on('click', 'a.edit-button', (event) => {
      event.preventDefault();

      var yab_id = $(event.currentTarget).parents('tr.yab-row').data('yab-id');
      $.ajax({
        type: 'GET',
        url: `/accounts/yabs/${yab_id}/edit`,
        success: (data) => {
          console.log("success yabs edit modal");
          $('#yab-edit-modal .body-wrapper').html(data);
          $('#form-alert').addClass('d-none');
          $('#yab-edit-modal').modal('show');
          $('#yab-edit-modal').data('yab-id', yab_id);
        },
        fail: (data) => {
          show_layout_modal("Failed to get edit form");
        }
      })
    });

    $('#yab-edit-modal').on('submit', 'form', (event) => {
      var formElt = event.currentTarget;
      event.preventDefault();
      var yab_id = $('#yab-edit-modal').data('yab-id');
      $.ajax({
        url: $(formElt).prop('action'),
        type: 'PUT',
        dataType: 'json',
        data : $(formElt).serialize(),
        success: (data) => {
          $(`#yab-row-${yab_id}`).html(data.table_row);
          $('#yab-edit-modal').modal('hide');
          show_layout_modal("Your yab was successfully updated.");
        },
        error: (data) => {
          var json = data.responseJSON;
          console.log(`err: ${json.errors}, code: ${data.status}`);
          $('#form-alert').html(json.errors.join(', '));
          $('#form-alert').removeClass('d-none');
        }
      });
      return false;
    });
  }

  setup_pagination_buttons() {
    $('#yabs-list-container').on('click', '#btn-next', (event) => {
      var page_num = $('#yabs-list-container #yabs-table').data('page-num');
      var max_page_num = $('#yabs-list-container #yabs-table').data('max-page-num');
      if (page_num < max_page_num) {
        $('#yabs-list-container #yabs-table').data('page-num', page_num + 1);
        this.load_list($("#yabs-table").data('sort-column'), $("#yabs-table").data('sort-order'));
      }
    });

    $('#yabs-list-container').on('click', '#btn-prev', (event) => {
      var page_num = $('#yabs-list-container #yabs-table').data('page-num');
      if (page_num > 0) {
        $('#yabs-list-container #yabs-table').data('page-num', page_num - 1);
        this.load_list($("#yabs-table").data('sort-column'), $("#yabs-table").data('sort-order'));
      }
    });
  }
}