export function vpsb_lazy_load_images() {
  var lazyloadImages;

  if ("IntersectionObserver" in window) {
    lazyloadImages = document.querySelectorAll(".lazy-image");
    var imageObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          var image = entry.target;
          image.src = image.dataset.src;
          image.classList.remove("lazy-image");
          imageObserver.unobserve(image);
        }
      });
    }, { rootMargin: "150px" });

    lazyloadImages.forEach(function(image) {
      imageObserver.observe(image);
    });
  }
}