import VChartsLoader from './lib/vcharts_loader.js';
import { intersectionObserverLazyLoad } from './global';

class BestVpsPage {
  constructor() {
    this.vchartsLoader = new VChartsLoader();
  }

  init() {
    this.setupLazyLoadCharts();
  }

  setupLazyLoadCharts() {
    intersectionObserverLazyLoad("lazy-chart", "500px", (container) => {
      this.load_chart($(container));
    });
  }

  load_all_charts() {
    $('.grades_chart').each( (index, element) => {
      this.load_chart($(element));
    });
  }

  load_chart(canvas) {
    if (canvas.length == 0) {
      console.error(canvas.id + " is missing from best_vps page");
      return;
    }

    var ctx = canvas.get(0).getContext("2d");

    var when = canvas.data('when');
    var category = canvas.data('category');
    var price_weighted = canvas.data('price-weighted')
    var max_price = canvas.data('max-price');
    var revision = canvas.data('revision');

    var chart_url;
    var chart_data;
    if (max_price) {
      if (revision) {
        chart_url = `/best_vps/${when}/grades_chart/under/${max_price}/revision/${revision}`;
        chart_data = {};
      }
      else {
        chart_url = `/best_vps/${when}/grades_chart/under/${max_price}`;
        chart_data = {};
      }
    }
    else {
      chart_url = `/best_vps/${when}/grades_chart/${category}`;
      chart_data = { price_weighted: price_weighted };
    }

    $.ajax({
      type: 'GET',
      url: chart_url,
      data: chart_data,
      dataType: 'json',
      success: (data)=> {
        console.debug("success best_vps grades_chart");
        this.display_chart(ctx, data);
      }
    })
  }

  display_chart(ctx, data) {
    this.vchartsLoader.vpsb_bar_chart_improved(ctx, data);
  }
}

$(document).on('turbo:load', function() {
  if ($("body.best_vps.show").length == 0 && $("body.best_vps.show_year").length == 0 ) {
    return;
  }
  console.log("page load in best_vps.js");

  var best_vps_page = new BestVpsPage();
  best_vps_page.init();

  $('[data-toggle="popover"]').popover();
} );
