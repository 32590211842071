export class SelectizeMultipleSelect {
  constructor(selectId, placeholder, dataName, defaultValue, submitForm, copySelectBox = true) {
    this.selectId = selectId;
    this.selectCopyId = `${this.selectId}-copy`;
    this.placeholder = placeholder;
    this.dataName = dataName;
    this.submitForm = submitForm;
    this.defaultValue = defaultValue;
    this.selectedValues = [];
    this.copySelectBox = copySelectBox;
  }

  init_select() {
    if ($(this.selectId).siblings('.selectize-control').length > 0) {
      console.debug(`removing selectize container for ${this.selectId}`);
      $(this.selectId).siblings('.selectize-control').remove();
    }

    if (this.copySelectBox) {
      // Must copy the original select box to preserve the list of option items.
      // That's because selectize clears that list of all not selected options as soon
      // as it initializes. This causes problems with Turbo moving away and coming back.
      if ($(this.selectCopyId).length > 0) {
        console.debug("removing original select");
        $(this.selectId).remove();
        var selectCopy = $(this.selectCopyId).clone();
        selectCopy.attr('id', this.selectId.replace('#', ''));
        selectCopy.insertBefore($(this.selectCopyId));
    } else {
        console.debug("cloning original select");
        var selectCopy = $(this.selectId).clone();
        selectCopy.attr('id', this.selectCopyId.replace('#', ''));
        selectCopy.insertAfter($(this.selectId));
        selectCopy.hide();
      }
    }

    this.selectizeControl = $(this.selectId).selectize({
      persist: true,
      closeAfterSelect: true,
      placeholder: this.placeholder,
      plugins: ["remove_button"],
      searchField: ["optgroup", "text"],
      render: {
        item: (item, escape) => {
          if (typeof item.optgroup !== 'undefined') {
            return '<div class="item">' + item.optgroup + ' - ' + escape(item.text) + '</div>';
          } else {
            return '<div class="item">' + escape(item.text) + '</div>';
          }
        }
      },
    })[0].selectize;

    let selectizeControlElement = $(this.selectId).siblings('.selectize-control')[0];
    this.selectizeInputElement = $(selectizeControlElement).find(".selectize-input")
    $(this.selectizeInputElement).find("input").css("width", "160px");

    this.reset_select_selected();
  }

  submit_form() {
    if (typeof this.submitForm !== 'undefined') {
      this.submitForm();
    }
  }

  reset_select_selected() {
    if (typeof $(this.selectId).data(this.dataName) === 'undefined') {
      return;
    }

    var s = $(this.selectId).data(this.dataName).toString();
    console.debug("reset_select_selected", s);
    this.update_selected(s.split(','));
  }

  save_selected_values(selected) {
    if (Array.isArray(selected)) {
      this.selectedValues = [...selected];
    } else {
      this.selectedValues = [ selected ];
    }
    console.debug("saving selectedValues to", selected, this.selectedValues);

    $(this.selectId).attr(`data-${this.dataName}`, this.selectedValues.join(','));
    $(this.selectId).data(this.dataName, this.selectedValues.join(','));
    $(this.selectCopyId).attr(`data-${this.dataName}`, this.selectedValues.join(','));
    $(this.selectCopyId).data(this.dataName, this.selectedValues.join(','));
    $(this.selectCopyId).val(this.selectedValues);
  }

  update_selected(selected) {
    this.save_selected_values(selected);
    this.selectizeControl.clear();
    this.selectizeControl.setValue(this.selectedValues);
    this.selectizeControl.refreshItems();
  }

  selection_changed() {
    console.debug("selection_changed selectedValues & getValue:", this.selectedValues, this.selectizeControl.getValue());
    var control_values = this.selectizeControl.getValue();
    if (!Array.isArray(control_values)) {
      control_values = [ control_values ];
    }
    if (JSON.stringify(this.selectedValues.sort()) !== JSON.stringify(control_values.sort())) {
      return true;
    } else {
      return false;
    }
  }

  setup_select_changes() {
    this.selectizeControl.on('change', () => {
      var selected = this.selectizeControl.getValue();
      console.debug('change event', selected);
    });

    this.selectizeControl.on('type', () => {
      var inputElt = $(this.selectizeInputElement).find("input")
      console.debug('type event', this.selectizeControl.getValue(), inputElt.length);

      inputElt.css("width", "160px");
    });

    this.selectizeControl.on('item_add', (event, params) => {
      if (!this.selection_changed()) {
        console.debug("item_add: no change");
        return;
      }

      var addedId = $(params[0]).data('value');
      console.log('item_add adding selected:', addedId);
      if (typeof this.defaultValue !== 'undefined' && addedId == this.defaultValue) {
        console.log(`item_add: ${addedId} == ${this.defaultValue}`);
        this.ignoreNextItemEvent = true;
        this.update_selected([this.defaultValue]);
      } else {
        var selected = this.selectizeControl.getValue();
        this.save_selected_values(selected);

        if (typeof this.defaultValue !== 'undefined') {
          console.log(`item_add: ${addedId} != ${this.defaultValue} - selected=`, selected);
          var index = selected.indexOf(this.defaultValue);
          if (index !== -1) {
            selected.splice(index, 1);
            console.log(`item_add: setting value to`, selected);
            this.update_selected(selected);
            console.log(`item_add: selected after setValue ${$(this.selectId).val()}`);
          } else {
            this.selectizeControl.refreshItems();
          }
        }
      }
      this.submit_form();
    });

    this.selectizeControl.on('item_remove', (event, params) => {
      if (!this.selection_changed()) {
        console.debug("item_remove: no change");
        return;
      }

      console.log('item_remove', params[0]);
      if (typeof this.defaultValue !== 'undefined' && $(this.selectId).val().length == 0) {
        console.log('item_remove: nothing left, adding All');
        this.update_selected([this.defaultValue]);
      }
      setTimeout(() => { this.selectizeControl.close() }, 200);
      this.submit_form();
    });
  }
}